export default [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Login" */ './views/Home.vue')
    },    
    {
      path: '*',
      component: () => import(/* webpackChunkName: "Sales" */ './views/Home.vue'),      
    }     
  ]