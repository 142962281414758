<template>
  <div>    
    <antModal v-model="visible" :title="eventTitle" :closable="false" :maskClosable="false" :centered="true" :footer="null" :width="750">
        <ValidationObserver ref="observer" tag="form">           
        <b-form @submit.prevent="updateEvent" > 
            <b-row>
                <b-col>
                    <ValidationProvider name="tipos grupo" rules="required">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Locación</strong></label>                                                       
                            <b-form-select
                                :state="errors[0] ? false : (valid ? true : null)"
                                @change="onChangeLocation($event)"
                                v-model="selectedEvent.locacion"
                                :value="selectedEvent.locacion"
                            >
                            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{location.name}}</option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>                                             
                </b-col> 
                <b-col>
                    <ValidationProvider rules="required" name="evento">
                        <b-form-group slot-scope="{ valid, errors }">
                        <label> <strong>Nombre evento</strong></label>                            
                        <b-input
                            placeholder="Ingrese nombre del evento"
                            v-model="selectedEvent.nombreEvento"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                    </ValidationProvider>                       
                </b-col>              
            </b-row>           
            <b-row>
                <b-col>                                                
                        <ValidationProvider name="montaje" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Montaje</strong></label>                             
                            <v-select
                                @search:blur="blurSelectEditMontaje"
                                @input="_setMontaje"
                                :options="computedMontajes"
                                label="name"
                                v-model="montajeEdit"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                         <!-- {{montajeEdit}} -->
                    </b-col>
                    <b-col>
                        <ValidationProvider name="backup" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Backup</strong></label>                             
                            <v-select
                                @search:blur="blurSelectEditBackup"
                                @input="_setBackup"                                
                                :options="computedBackups"
                                label="name"
                                v-model="backupEdit"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>                            
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                                                
                    </b-col>              
                <b-col>  
                    <div class="text-center" v-if="isLoadingGroups">							
                        <b-spinner label="Loading..." variant="success"/><br>
                        <strong>Cargando grupo </strong>
                    </div>                                     
                    <ValidationProvider name="grupo" rules="required" v-if="!isLoadingGroups">
                        <b-form-group slot-scope="{ errors }">
                        <label> <strong>Grupo</strong></label>                             
                        <v-select
                            disabled                       
                            :filterable="false"    
                            @search="blurSelectEditGroup"
                            @input="_setGroup"
                            :options="groups"
                            label="name"
                            v-model="groupSelectedEdit"                
                            :class="{'is-invalid': !!errors.length}">
                        </v-select>
                        <!-- {{groupSelectedEdit}} -->
                        <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>                     
                </b-col>
            </b-row>
                    
            <b-row>
                <b-col>
                    <ValidationProvider rules="required" name="Fecha llegada">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha inicio</strong></label>                
                            <b-input
                                id="dateStartEdit"
                                @change="onStartDateChange(selectedEvent.startDate)" 
                                v-model="selectedEvent.startDate"                                
                                type="date" 
                                format="DD/MM/Y"
                                :state="errors[0] ? false : (valid ? true : null)"    
                                                            
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>                    
                </b-col>
                <b-col>
                    <b-form-group>
                        <label> <strong>Hora inicio</strong></label><br>                        
                            <antTimePicker @change="onHourChangeInit" :minuteStep="5" :value="moment(selectedEvent.startTime,'HH:mm')" format="HH:mm" :v-model="moment(selectedEvent.startTime,'HH:mm')"></antTimePicker>           
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ValidationProvider rules="required" name="Fecha fin">
                        <b-form-group slot-scope="{ valid, errors }">                
                            <label> <strong>Fecha fin</strong></label>                   
                            <b-input
                                id="dateEndEdit"
                                @change="onEndDateChange(selectedEvent.endDate)"
                                v-model="selectedEvent.endDate"
                                format="DD/MM/Y"
                                type="date"                                                                               
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>                    
                </b-col>
                <b-col>
                    <b-form-group>
                        <label> <strong>Hora Fin</strong></label><br>                        
                        <antTimePicker @change="onHourChangeEnd" :minuteStep="5" :value="moment(selectedEvent.endTime,'HH:mm')" format="HH:mm" :v-model="moment(selectedEvent.endTime,'HH:mm')"></antTimePicker>           
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ValidationProvider rules="required" name="Adultos">
                    <b-form-group slot-scope="{ valid, errors }">
                        <label> <strong>Adultos</strong></label>
                        <b-input type="number" placeholder="Número de adultos" v-model="selectedEvent.adult" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>                        
                    </ValidationProvider>
                    
                </b-col>
                <b-col>
                    <ValidationProvider rules="required" name="Niños">
                    <b-form-group slot-scope="{ valid, errors }">
                        <label> <strong>Niños</strong></label>
                        <b-input type="number" placeholder="Número de niños" v-model="selectedEvent.child" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>                        
                    </ValidationProvider>                        
                </b-col>
                <b-col>
                    <ValidationProvider rules="required" name="Infantes">
                    <b-form-group slot-scope="{ valid, errors }">
                        <label> <strong>Infantes</strong></label>
                        <b-input type="number" placeholder="Número de infantes" v-model="selectedEvent.infant" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>                        
                    </ValidationProvider>                    
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="danger" @click="closeModalEditEvent">Cerrar</b-button>
                </b-col>
                <b-col>
                    <b-button type="submit" variant="success" class="float-right">Actualizar evento</b-button>
                </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>                                                              
    </antModal>
  </div>
</template>
<script>


import Vue from 'vue'
import * as moment from 'moment'
import { mapActions, mapState, mapMutations} from 'vuex'

export default {
    name: 'EditEvent',
    props:{
        visible: {
            type: Boolean,
            required: true
        }, 
        selectedEvent:{
            type:Object,
            required: true
        }
              
    },
    mounted(){        
    },
    data() {
        return {
            loading: false, 
            nombreEvento: this.selectedEvent.nombreEvento,
            fechaInicioEvento: this.selectedEvent.startDate,
            horaInicioEvento: this.selectedEvent.startTime,
            fechaFinEvento: this.selectedEvent.endDate,
            horaFinEvento: this.selectedEvent.endTime,
            montajeEvento: this.selectedEvent.montaje,
            adultosEvento: this.selectedEvent.adult,
            niniosEvento: this.selectedEvent.child,
            infantesEvento: this.selectedEvent.infant, 
            locacionEvento: this.selectedEvent.locacion,
            backupEvento: this.selectedEvent.backup,                        
        }
    },   
    computed: {        
        ...mapState('init', ['locations','events','idUser']),                    
        ...mapState('calendar', ['groups','selectedLocation','isOpenModalEditEvent','eventsInSelectedDate','isLoadingGroups']),
        eventTitle(){            
            return `Actualizar evento ${this.selectedEvent.startDate} `
        },
        computedFormEditEvent(){
            return this.$refs.observer
        },
        computedMontajes(){                       
            let location = this.locations.find( location => location.id === parseInt(this.selectedLocation))                        
            return location.montaje || []                     
        },
        computedBackups(){
            const location = this.locations.find( location => location.id === parseInt(this.selectedLocation))
            return location.backup ? location.backup : []                     
        },
        montajeEdit: {
            get(){
                const idMontaje = this.selectedEvent.montaje ? this.selectedEvent.montaje.id : 0
                const location =  this.locations.find( location => location.id === parseInt(this.selectedLocation) )
                const montaje = location.montaje.find( l => l.id === parseInt(idMontaje) )
                return  montaje || []
            },
           set( montaje ){            
               const id  = montaje ? montaje.id : null
               return this.locations.find( montaje => montaje.id === id )             
           } 
        },
        backupEdit: {
            get(){       
                const idBackup = this.selectedEvent.backup ? this.selectedEvent.backup : 0
                const location =  this.locations.find( location => location.id === parseInt(this.selectedLocation) )
                const backup = location.backup.find( l => l.id === parseInt(idBackup) )
                return  backup || []
            },
            set( backup ){
               const id  = backup ? backup.id : null
               return this.locations.find( backup => backup.id === id )            
           } 
        },
        eventSelectedEdit: {
            get(){
                return this.events.find( event => event.id === this.selectedEvent.evento )
            },
           set( evento ){
               const id  = evento ? evento.id : null
               return this.events.find( event => event.id === id )             
           } 
        },
        groupSelectedEdit: {
            get(){
                return this.groups.find( group => group.id === this.selectedEvent.grupo.id )                
            },
            set( grupo ){
               const id  = grupo ? grupo.id : null
                return this.groups.find( group => group.id === id )             
           } 
        },                               
    },
    methods: {
        moment,
        ...mapActions('calendar',['fetchGroupsSchedulerByName','validateEventUpdate','getEventsAndTotals','fetchGroupsScheduler']),
        ...mapMutations('calendar',['unsetSelectedEvent','setEventsInSelectedDate',]),
        async updateEvent(){            
            const fechaInicio = document.getElementById('dateStartEdit').value
            const fechaFin = document.getElementById('dateEndEdit').value             
            const isValidDates = moment(fechaFin).isSameOrAfter(fechaInicio, 'day')              
            const success = await this.computedFormEditEvent.validate()            
            //valido los campos que sean requeridos
            if (!success) {
                return
            } 
            //valido que grupo no venga nulo
            if( !this.selectedEvent.grupo ){                
                Vue.$toast.open({
                    message: `<strong>Debes seleccionar un grupo</strong>`,
                    type: 'error',
                    position:'top-right'
                })
                return
            }  
            //valido que las fechas sean correctas
            if( !isValidDates ){
                Vue.$toast.open({
                    message: `<strong>Fecha fin no debe ser menor a fecha de inicio</strong>`,
                    type: 'error',
                    position:'top-right'
                })
                return
            }
            const dataEvent = {                 
                updatedby: this.idUser,
                id: this.selectedEvent.id, //para validar en /grupos/validacion/  
                idLoc: this.selectedLocation, //para validar en /grupos/validacion/ 
                fini: fechaInicio, //para validar en /grupos/validacion/ 
                ffin: fechaFin, //para validar en /grupos/validacion/ 
                hini: this.selectedEvent.startTime, //para validar en /grupos/validacion/ 
                hfin: this.selectedEvent.endTime, //para validar en /grupos/
                id: this.selectedEvent.id,
                nombreEvento: this.selectedEvent.nombreEvento,
                endDate: this.selectedEvent.endDate,
                endTime: this.selectedEvent.endTime,                                               
                grupo: this.selectedEvent.grupo.id,
                locacion: this.selectedEvent.locacion,
                montaje: this.selectedEvent.montaje ? this.selectedEvent.montaje.id : null,                
                backup: this.selectedEvent.backup,
                startDate: this.selectedEvent.startDate,
                startTime: this.selectedEvent.startTime,
                adult: this.selectedEvent.adult,
                child: this.selectedEvent.child,
                infant: this.selectedEvent.infant

            }
            if (!dataEvent.backup) {
                delete dataEvent.backup
            }
            if (!dataEvent.montaje) {
                delete dataEvent.montaje        
            } 
                                                                                             
            await this.validateEventUpdate( dataEvent ) //valida y actualiza 
            //si se cambia  locacion a una diferente de la locación selecionada
            if( this.selectedLocation != this.selectedEvent.locacion ){
                const idEvent = this.selectedEvent.id
                const eventos = this.eventsInSelectedDate.filter( e => e.id !== idEvent ) 
                this.setEventsInSelectedDate( eventos )
            }             
            if( !this.isOpenModalEditEvent ){
                this.unsetSelectedEvent() 
                this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')                
                await this.getEventsAndTotals()//calendar module 
                                                  

            }                 
        },                    
        closeModalEditEvent(){    
            this.selectedEvent.nombreEvento = this.nombreEvento
            this.selectedEvent.startDate = this.fechaInicioEvento
            this.selectedEvent.startTime = this.horaInicioEvento
            this.selectedEvent.endDate = this.fechaFinEvento
            this.selectedEvent.endTime = this.horaFinEvento
            this.selectedEvent.montaje = this.montajeEvento
            this.selectedEvent.adult = this.adultosEvento
            this.selectedEvent.child = this.niniosEvento
            this.selectedEvent.infant = this.infantesEvento
            this.selectedEvent.locacion = this.locacionEvento
            this.selectedEvent.backup = this.backupEvento            
            this.$emit('closeModalEditEvent')
        },
        blurSelectEditMontaje(){            
            this.computedFormEditEvent.refs.montaje.validate()
        },
        blurSelectEditBackup(){            
            this.computedFormEditEvent.refs.backup.validate()
        },
        blurSelectEditEvent(){            
            this.computedFormEditEvent.refs.evento.validate()
        },
        async blurSelectEditGroup( search, loading ){ 
            const nameGroup = search              
            if( search.length == 5) {
                loading(true)
                const payload =  { nameGroup }              
                await this.fetchGroupsSchedulerByName( payload )
                loading(false)                
            }                                
        },
        onChangeLocation(eventChange){
            this.selectedEvent.location = eventChange                    
        },                
        onStartDateChange( dateString ){
            if( dateString && dateString !== ''){
                this.selectedEvent.startDate = dateString                
            }                      
        },
        onEndDateChange( dateString ){
            if( dateString && dateString !== ''){
                this.selectedEvent.endDate = dateString                
            }                    
        },
        onHourChangeInit( hour ){
            if( hour && hour !== ''){
                this.selectedEvent.startTime = hour.format("HH:mm:ss")               
            }                   
        },
        onHourChangeEnd( hour ){
            if( hour && hour !== ''){
                this.selectedEvent.endTime = hour.format("HH:mm:ss")               
            }                     
        },     
         _setMontaje( montaje ){             
            if(montaje){                
                this.selectedEvent.montaje = montaje            
            }
            if( !montaje ){
                this.selectedEvent.montaje = null            
            }
        },
        _setBackup( backup ){
            if( backup ){
                const { id } =  backup  
                this.selectedEvent.backup = id            
            }
            if( !backup ){
                this.selectedEvent.backup = null            
            }
        },
        _setGroup( grupo ){            
            if(grupo){ 
                this.selectedEvent.grupo = grupo            
            }
            if( !grupo ){
                this.selectedEvent.grupo = null            
            }
        }        
    },
}

</script>
