<template>
    <div>
        <b-card class="mb-1 cardGroup" :class="cardGroupIsActive ? 'boderSelectedLeftGroupCard' : 'bodersuccessLeftGroupCard'"            
        >             
            <b-row class="mb-2">
                <b-col class="text-justify">
                    <strong style="font-size:.80em">{{group.name}}</strong>                    
                </b-col>  
                <b-col>
                    <strong style="font-size:.80em">{{ nameHotel( group.grouphotel ) }}</strong><br>
                </b-col>              
            </b-row>            
            <b-row class="mb-2">
                <b-col class="text-justify">
                    <strong style="font-size:.80em">Llegada</strong>  <br>
                    <span style="font-size:.75em">{{group.arrivaldate}}</span>
                </b-col>
                <b-col>
                    <strong style="font-size:.80em">Salida</strong>   <br>
                    <span style="font-size:.75em">{{group.departuredate}} </span>                    
                </b-col>
                <b-col>
                    <strong style="font-size:.75em">AD-NI-INF</strong><br>
                    <span class="badge badge-secondary">{{group.adult}}</span>-<span class="badge badge-secondary">{{group.child}}</span>-<span class="badge badge-secondary">{{group.infant}}</span>                     
                </b-col>
            </b-row>                    
            <b-row>
                <b-col class="text-justify">
                   <strong style="font-size:.80em">Coordinador:</strong> {{ nameCoordinador( group.coordinador ) }}
                </b-col>
                <b-col>
                    <b-button class="float-right" variant="info" style="padding: 0.1rem 0.15rem;" @click="editGroup" >
                        <b-icon icon="pencil-fill" ></b-icon>
                    </b-button>
                </b-col>
            </b-row>         
            <b-row>
                <b-col class="text-justify">                                                          
                    <span
                        class="badge badge-success mr-2"
                        v-b-toggle="'collapse'+group.id"
                        v-if="group.calendario ? group.calendario.length : group.calendario"
                        @click="_setSelectedGroup()"
                    >
                        Ver locaciones
                    </span>
                    <span
                        v-else
                        style="cursor: pointer;"
                        class="badge badge-warning"
                        @click="_setSelectedGroup()"
                    >Sin locaciones</span>
                    <b-button variant="primary" size="sm" @click="openModalListEventsGroup">
                        <b-icon icon="exclamation-circle-fill"></b-icon>
                    </b-button>

                    <ListEventsGroup
                        v-if="group.calendario"
                        :visible="visibleListEventsGruop"
                        :grupo="group"                                                                     
                        @close-modal-list-events-group="closeModalListEventsGroup"
                    /> 
                                    
                    <b-collapse :id="'collapse'+group.id" >
                        <b-card style="max-width: 100%; border-right: 4px solid #4db6ac;" class="mb-1" v-for="(evento, index) in group.calendario" :key="index">
                            <b-card-text>                                       
                                <b-row>
                                    <b-col>
                                        <strong style="font-size:.80em">
                                            {{  evento.nombreEvento  }}
                                        </strong>  
                                    </b-col>                                    
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <strong style="font-size:.75em">Fecha hora inicio</strong><br>
                                        <span style="font-size:.80em">{{evento.startDate}}-{{evento.startTime}}</span>
                                        
                                    </b-col>
                                    <b-col>
                                        <strong style="font-size:.75em">Fecha hora fin</strong><br>
                                        <span style="font-size:.80em">{{evento.endDate}}-{{evento.endTime}}</span>                    
                                        
                                    </b-col>
                                </b-row> 
                                <b-row>
                                </b-row>                               
                                <b-row>
                                    <b-col>
                                        <strong style="font-size:.80em">Locación: </strong>
                                        <span style="font-size:.75em">{{ nameLocation( evento.locacion ) }} </span>
                                        
                                    </b-col>
                                    <b-col>
                                        <strong style="font-size:.80em">Montaje: </strong>  
                                        <span style="font-size:.78em">{{evento.montaje ? evento.montaje.name : ''}} </span>
                                                                             
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>                                        
                                        <strong style="font-size:.70em">AD-NI-INF</strong><br>
                                        <span class="badge badge-primary">{{evento.adult}}</span>-<span class="badge badge-primary">{{evento.child}}</span>-<span class="badge badge-primary">{{evento.infant}}</span>                                        
                                    </b-col> 
                                    <b-col>
                                        <div class="float-right">
                                            <b-button size="sm" variant="info" class="mr-1 mb-1 button-small"
                                                @click.stop="editEvent(evento)"                                        
                                            >
                                                <b-icon icon="pencil-fill" ></b-icon>
                                            </b-button>                                    
                                            <b-button size="sm" variant="danger" class="mr-1 mb-1 button-small" 
                                                @click.stop="deleteEvent(evento)"
                                            >
                                                <b-icon icon="x"></b-icon>                                   
                                            </b-button>                       
                                        </div>
                                    </b-col>         
                                </b-row>                                 
                                <hr style="width:100%;text-align:left;margin-bottom:0.2em; border: 1px solid #82b1ff">
                                <b-row>
                                    <b-col>   
                                        <strong style="font-size:.75em" v-if="evento.backup">Backup: {{ evento.backup ? nameLocation(evento.backup) : '' }}</strong>
                                        <span v-else class="badge badge-warning" >Sin backup establecido</span> <br>                                    
                                        <strong style="font-size:.70em">Creado por: {{ evento.createdby ? evento.createdby.first_name+' '+evento.createdby.last_name : '' }}</strong> <br>
                                        <strong style="font-size:.70em">Actualizado por: {{ evento.updatedby ? evento.updatedby.first_name+' '+evento.updatedby.last_name : '' }}</strong>
                                    </b-col>
                                </b-row>                              
                            </b-card-text>
                        </b-card>                       
                    </b-collapse>
                </b-col>                               
            </b-row>                
    </b-card>
  </div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'

import ListEventsGroup from '@/components/groups/ListEventsGroup'

export default {
    name: 'Group',
    props:{
        group: {
            type: Object,
            required: true
        }        
    },
    components: {
        ListEventsGroup
    },
    data() {
        return {
            cardGroupIsActive: false, 
            visibleListEventsGruop: false  
        }
    },
    computed: {        
        ...mapState('init', ['locations', 'hotels','coordinators','events']),                        
        ...mapState('calendar', ['eventsData']), 
        dataGrupo(){
            let data = this.group.calendario || []
            data.sort(function(a,b){                                                        
                return this.group.calendario = new Date(`${b.startDate} ${b.startTime}`) - new Date(`${a.startDate} ${a.startTime}`);
            })
            return this.group
        }               
    },
    methods: {  
        ...mapMutations('calendar',['setSelectedLocation','setSelectedGroup']),
        _setSelectedGroup(){
            const elements = document.getElementsByClassName('cardGroup')            
            
            for (let i = 0; i < elements.length; i++) {       
                elements[i].classList.remove('boderSelectedLeftGroupCard')
                elements[i].classList.add('bodersuccessLeftGroupCard')
            }                
            this.cardGroupIsActive = !this.cardGroupIsActive            
            this.setSelectedGroup( this.group )
        },   
        editGroup(){        
            this.$emit('edit-group', this.group)
        },        
        nameEvent( idEvent ){
            const event = this.events.find( event => event.id === idEvent )            
            return event ? event.name : ''
        },
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },
        nameHotel( idHotel ){
            const item = this.hotels.find( hotel => hotel.id === idHotel )            
            return item ? item.name : ''
        },
        nameCoordinador( idCoordinador ){
            const item = this.coordinators.find( coord => coord.id === idCoordinador )            
            return item ? item.name : ''
        },
        editEvent( evento ){ 
            this.setSelectedLocation(evento.locacion)
            this.$emit('edit-event', evento )
        },        
        deleteEvent( evento ){        
            this.$emit('delete-event', evento )
        },
        openModalListEventsGroup(){
            this.visibleListEventsGruop = true
        },
        closeModalListEventsGroup(){
            this.visibleListEventsGruop = false
        }
    }
}
</script>
<style>
    .bodersuccessLeftGroupCard {
        border-left: 4px solid #5cb85c;
    }
    .boderSelectedLeftGroupCard {
        border-left: 4px solid #ad1457;
    }
</style>