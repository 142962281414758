
export const monthsOptions = [          
  { value: 0, text: 'Enero' },          
  { value: 1, text: 'Febrero' },          
  { value: 2, text: 'Marzo' },          
  { value: 3, text: 'Abril' },  
  { value: 4, text: 'Mayo' },          
  { value: 5, text: 'Junio' },          
  { value: 6, text: 'Julio' },          
  { value: 7, text: 'Agosto' },
  { value: 8, text: 'Septiembre' },          
  { value: 9, text: 'Octubre' },          
  { value: 10, text: 'Noviembre' },          
  { value: 11, text: 'Diciembre' },        
]

export const seedTotal = [  
  {
    fecha: "2022-02-01",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-02",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-03",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-04",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-05",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-06",
    total:Math.floor(Math.random() * 101)
  },

  {
    fecha: "2022-02-07",
    total:Math.floor(Math.random() * 101)
  },

  {
    fecha: "2022-02-08",
    total:Math.floor(Math.random() * 101)
  },
 
  {
    fecha: "2022-02-09",
    total:Math.floor(Math.random() * 101)
  },

  {
    fecha: "2022-02-10",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-11",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-12",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-13",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-14",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-15",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-16",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-17",
    total:Math.floor(Math.random() * 101)
  },

  {
    fecha: "2022-02-18",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-19",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-20",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-21",
    total:Math.floor(Math.random() * 101)
  },
  
  {
    fecha: "2022-02-22",
    total:Math.floor(Math.random() * 101)
  },
 
  {
    fecha: "2022-02-23",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-24",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-25",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-26",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-28",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-28",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-29",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-30",
    total:Math.floor(Math.random() * 101)
  },
  {
    fecha: "2022-02-31",
    total:Math.floor(Math.random() * 101)
  }
]