import * as moment from 'moment'

export default {
    selectedDate: moment(),
    isOpenModalCreateEvent: false,
    isOpenModalEditEvent: false,
    days:[],
    filter: {
        query: '',
    },
    eventsData: null,    
    totalEventsByMonth: null,    
    eventsInSelectedDate:[],    
    groups:[],
    isLoadingGroups: false,
    isLoadingEvents: false,
    calendarError: null,    
    selectedEvent: null,
    selectedLocation: null,
    selectedGroup: null,    
}