
export function setDaysMonth(state, days) {
    state.days = days
}

export function setEventsData(state, events) {
    state.eventsData = events
}

export function setTotalEventsByMonth(state, totalEvents) {
    state.totalEventsByMonth = totalEvents
}

export function setEventsInSelectedDate(state, eventos) {
    state.eventsInSelectedDate = eventos
}

export function setGroupsData(state, groups) {
    state.groups = groups
}

export function setSelectedLocation(state, idLocation) {
    state.selectedLocation = idLocation
}

export function setSelectedDate(state, date) {
    state.selectedDate = date
}

export function setSelectedGroup(state, group) {
    state.selectedGroup = group
}

export function setSelectedEvent(state, event) {
    state.selectedEvent = event
}

export function setVisibilityModalCreateEvent(state, bool) {
    state.isOpenModalCreateEvent = bool
}

export function setVisibilityModalEditEvent(state, bool) {
    state.isOpenModalEditEvent = bool
}

export function setLoadingEvents(state, bool) {
    state.isLoadingEvents = bool
}

export function setLoadingGroups(state, bool) {
    state.isLoadingGroups = bool
}

export function setCalendarError(state, error) {
    state.calendarError = error
}

export function unsetSelectedEvent(state) {
    state.selectedEvent = null
}