<template>
  <div>    
    <antModal v-model="visible" :title="grouTitle" :closable="false" :maskClosable="false" :width="750" :centered="true" :footer="null">
        <ValidationObserver ref="form" tag="form">
            <b-form @submit.prevent="addGroup" ref="formGroupCreate">
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="nombre grupo">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Nombre grupo*</strong></label>                                         
                            <b-input placeholder="Nombre del grupo" v-model="name" :state="errors[0] ? false : (valid ? true : null)" ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="clasificacion">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Clasificación*</strong></label>                            
                            <b-input
                                placeholder="Clasificación del grupo"
                                v-model="clasificacion"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                    <b-col>  
                        <div class="text-center" v-if="isLoadingGroupTypes">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando tipos </strong>
                        </div>                       
                        <ValidationProvider name="tiposGrupo" rules="required" v-if="!isLoadingGroupTypes">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Tipo grupo*</strong></label>                             
                            <v-select
                                @search:blur="blurSelectTiposGrupo"
                                :options="groupTypes"
                                label="tipogruponame"
                                v-model="groupType"
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="appellido contacto">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Apellido del contacto*</strong></label>                                         
                            <b-input placeholder="Apellido del contacto" v-model="apellidoContacto" :state="errors[0] ? false : (valid ? true : null)" ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="telefono del contacto">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Telefono del contacto*</strong></label>                            
                            <b-input
                                placeholder="Télefono"
                                v-model="telefonoContacto"
                                @keypress="formatPhoneNumber" 
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="correo electrónico">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Correo electrónico*</strong></label>                            
                            <b-input
                                type="email"
                                placeholder="email"
                                v-model="emailContacto"
                                @keypress="checkIfFormatEmailIsValid" 
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            <span style="color:red;"> {{ checkIfFormatEmailIsValid( emailContacto ) }}</span>

                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="" name="wedding id">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Wedding Id</strong></label><br>
                            <b-input placeholder="Ingrese Wedding Id" v-model="weddingId" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Fecha llegada">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha llegada</strong></label><br>                   
                            <b-input
                                id="dateArrival"
                                type="date"                                                        
                                v-model="arrivalDate"
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Fecha Salida">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha salida</strong></label><br>                   
                            <b-input
                                id="dateDeparture"
                                type="date"                        
                                v-model="departureDate"
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                        
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="Adultos">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Adultos*</strong></label>
                            <b-input type="number" placeholder="Número de adultos" v-model="adults" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Niños*</strong></label>
                            <b-input type="number" placeholder="Número de niños" v-model="children" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Infantes*</strong></label>
                            <b-input type="number" placeholder="Número de infantes" v-model="infants" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                    
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>                        
                        <ValidationProvider name="hoteles" rules="required">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Hotel*</strong></label>                            
                            <b-form-select 
                                :state="errors[0] ? false : (valid ? true : null)"                                
                                v-model="hotel">
                                <option value="">Seleccione hotel</option>
                                <option
                                    v-for="(groupType, index) in hotels"
                                    :key="index" :value="groupType.id"
                                >
                                {{groupType.name}}
                                </option>
                            </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider> 
                    </b-col>
                    <b-col>   
                        <div class="text-center" v-if="isLoadingAgencys">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando agencias </strong>
                        </div> 

                        <ValidationProvider name="agencia" rules="" v-if="!isLoadingAgencys">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Agencias</strong></label>                             
                            <v-select
                                @search:blur="blurSelectAgency"
                                :options="agencies"
                                label="name"
                                v-model="agency"
                                @input="_setAgencySelected"
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                       
                    </b-col> 
                    <b-col>
                        <ValidationProvider name="agente" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Agentes</strong></label>                             
                            <v-select
                                @search:blur="blurSelectAgent"
                                :options="agents"
                                label="name"
                                v-model="agent"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                         
                    </b-col>                       
                </b-row>
                <b-row>
                    <b-col>
                        <div class="text-center" v-if="isLoadingCoordinators">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando coordinadores </strong>
                        </div> 

                       <ValidationProvider name="coordinador" rules="" v-if="!isLoadingCoordinators">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Coordinador</strong></label>                            
                             
                            <v-select
                                @search:blur="blurSelectCoordinator"
                                :options="coordinators"
                                label="name"
                                v-model="coordinator"
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                       
                             
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Descripción">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Descripción</strong></label>
                            <b-form-textarea
                                id="textareaDescripcion"                        
                                placeholder="Breve descripción" 
                                v-model="description" 
                                :state="errors[0] ? false : (valid ? true : null)"                     
                            ></b-form-textarea>                                                     
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                        
                    </b-col> 
                                        
                </b-row>
                <b-row>
                    <b-col>
                        <b-button variant="danger" @click="closeModalCreateGroup">Cerrar</b-button>
                    </b-col>
                    <b-col>
                        <!-- :disabled="invalid" -->
                        <b-button type="submit" variant="success" class="float-right" >Crear grupo</b-button>
                    </b-col>
                </b-row>
            </b-form>                
        </ValidationObserver>          
    </antModal>
  </div>
</template>
<script>

import Vue from 'vue'
import * as moment from 'moment'
import { mapActions, mapMutations, mapState } from 'vuex'
import "vue-select/dist/vue-select.css"

export default {
    name: 'CreateGroup',
    props:{
        visible: {
            type: Boolean,
            required: true
        },
        selectedDate: {
            type: Object,
            required: true
        },        
    },
    data() {
        return {
            loading: false,            
            name: "", 
            clasificacion: "",
            apellidoContacto:"",
            telefonoContacto:"",
            emailContacto:"",            
            groupType: "",
            weddingId: "",              
            arrivalDate: "",
            departureDate: "",
            adults: 1,
            children: 0,
            infants: 0,
            hotel: null,
            agency: "",
            agent: "",
            coordinator: "",
            description:"",                        
        }
    },
    computed:{        
        ...mapState('init', ['idUser','groupTypes','hotels','agencies','agents','coordinators','agencySelected','isLoadingGroupTypes','isLoadingCoordinators','isLoadingAgencys']),     
        grouTitle(){ return `Crear grupo` },
        valueFechaLlegada(){
            return `${this.selectedDate.format('Y-MM-DD')}`
        },
        valueFechaSalida(){
            const dateDeparture = this.selectedDate.add(1, 'days')            
            return `${dateDeparture.format('Y-MM-DD')}`
        },
        computedForm(){
            return this.$refs.form
        }        
    },
    methods: {
        moment,
        ...mapActions('init',['fetchAgents']),    
        ...mapMutations('init',['setAgencySelected','setAgents']),
        blurSelectCoordinator(){
            this.computedForm.refs.coordinador.validate()
        },
        blurSelectTiposGrupo(){
            this.computedForm.refs.tiposGrupo.validate()
        },
        blurSelectAgency(){            
            this.computedForm.refs.agencia.validate()
        },
        blurSelectAgent(){            
            this.computedForm.refs.agente.validate()
        },
        async addGroup(){
            const fechaActual = moment().format("YYYY-MM-DD")
            const fechaInicio = document.getElementById('dateArrival').value
            const fechaFin = document.getElementById('dateDeparture').value
            const isValidDates = moment(fechaFin).isSameOrAfter(fechaInicio, 'day')
            const isValidDateStart = moment(fechaInicio).isSameOrAfter(fechaActual, 'day')
            const isValidDateEnd = moment(fechaFin).isSameOrAfter(fechaActual, 'day')
            const success = await this.computedForm.validate()    
                     
            if (!success) {
                return
            }
            if(fechaInicio !="" || fechaFin !=""){
                if(!isValidDates){
                    Vue.$toast.open({
                        message: `<strong>Fecha fin no debe ser menor a fecha de inicio, si va ocupar fechas considere llenar fechas de inicio/fin </strong>`,
                        type: 'error',
                        position:'top-right'
                    })
                    return
                }
                if( !isValidDateStart || !isValidDateEnd ){
                    Vue.$toast.open({
                        message: `<strong>Las fechas de llegada y salida no deben ser menores a la fecha actual</strong>`,
                        type: 'error',
                        position:'top-right'
                    })
                    return
                }                
            }  
            // return                                  
            this.$emit('addNewGroup',{ 
                createdby: this.idUser,
                name: this.name,
                clasificacion: this.clasificacion,
                tipogrupo: parseInt(this.groupType.id),
                contact: this.apellidoContacto,
                phonecontact: this.telefonoContacto,
                emailcontact: parseInt(this.email),
                weddingID: this.weddingId,
                arrivaldate: this.arrivalDate,
                departuredate: this.departureDate,
                adult: parseInt(this.adults),
                child: parseInt(this.children),
                infant:  parseInt(this.infants),
                grouphotel: this.hotel,
                agency: this.agency.id,
                agentname: this.agent.id,
                coordinador: this.coordinator.id,
                description: this.description
            })
            this.loading = false   
                               
            Object.assign(this.$data, this.$options.data() ) 
            this.$refs.form.reset()  //reseteo validacio veevalidate         
        },
        closeModalCreateGroup(){
            Object.assign(this.$data, this.$options.data() ) 
            this.$refs.form.reset() 
            this.$emit('closeModalCreateGroup')
        },
        async _setAgencySelected( agency ){
            const idAgency = agency ? agency.id : null                  
            if( idAgency != null ){
                this.agent = null
                await this.setAgencySelected( idAgency )
                await this.fetchAgents()
            }
            if( idAgency == null  ){
                await this.setAgents([])
                this.agent = null
            }            
        },
        formatPhoneNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {                                       
				event.preventDefault()
			}
        },
        checkIfFormatEmailIsValid( email ){     
            const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if( email !='' ){
                if( reg.test(email) ){                      
                    return ''
                }
                if( !reg.test(email) ){                       
                    return 'Email invalido'
                }
            }
        },       
    },
}

</script>
