<template>
  <div class="animate__animated animate__pulse">
    <b-row class="mb-3">
      <b-col class="col-sm-12 col-md-12 text-center">
        <h2> <strong> {{ monthNameSelectedDate }}</strong></h2>
        <!-- <h3> <strong>{{ selectedDateFormat }}</strong></h3>                -->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-sm-12 col-md-8 mb-3">
        <div class="form-inline" > 
          <b-form-group>
            <label style="color:white">x</label>            
              <button type="button" id="sidebarCollapse" class="btn btn-info mr-2" @click.stop="toggleSidebar( visibleSidebar )">
                <b-icon icon="list"></b-icon>
              </button>         
          </b-form-group >         
          <b-form-group class="mr-2">
            <label> <strong>Fecha</strong></label>            
            
            <b-form-input
              type="month"              
              v-model="dateYYMM"  
              :min="minYearMax.yearMin+'-01'"
              :max="minYearMax.yearMax+'-12'"            
              @input="changeDate"            
              >
            </b-form-input>        
            
          </b-form-group> 
          <b-form-group class="mr-2">
            <label> <strong>Hoteles</strong></label>
              <select class="form-control" v-model="hotel" @change="changeHotel($event)">
                <option value="all">Todos</option>
                <option v-for="(hotel, index) in hotels" :key="index" :value="hotel.id">{{hotel.name}}</option>
              </select>        
          </b-form-group>  
          <b-form-group>
            <label style="color:white">x</label>            
            <b-button  size="sm" variant="primary" @click=" refreshGridEvents"><b-icon icon="arrow-counterclockwise" :animation="isRefreshGridEvents ? 'spin-reverse':'' " font-scale="2"></b-icon></b-button>         
          </b-form-group> 
        
        </div> 
                                                                                
      </b-col>
      
      <b-col class="col-sm-12 col-md-4">
          <b-button
              v-if="currenMonthNumberDate !== monthNumberSelectedDate"
              class="float-right"
              variant="outline-primary"
              @click="setSelectedDateToToday"
          >
            <b-icon icon="house-fill"></b-icon>
          </b-button>
          <b-button class="float-right mr-2" size="sm" variant="primary" @click="openModalCreateGroup">Nuevo grupo</b-button> 
      </b-col> 
             
    </b-row>                 
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex'
import * as moment from 'moment'
import { yearsMinMax, getDaysArray } from '@/helpers/helpers' 
import store from '@/store/index'

export default {
  name: 'HeaderCalendar',
  props:{      
    currentMonthName:{
      type: String,
      required: true
    }       
  }, 
  mounted(){
     
  },
  data() {
    return {
        isRefreshGridEvents: false,        
        currenMonthNumberDate: moment().month(), 
        minYearMax: yearsMinMax(),
        // dateInput: moment(this.selectedDate).format("YYYY-MM-DD"),
        dateYYMM: moment(this.selectedDate).format("YYYY-MM"),
        location: this.selectedLocationFilter || 'all' ,          
        hotel: this.selectedHotelFilter || 'all' ,
        visibleSidebar: false          

    }
  },
  computed:{
    ...mapState('calendar', ['selectedDate', 'groups']),   
    ...mapState('init', ['hotels','selectedHotelFilter','selectedLocationFilter']),   
    ...mapGetters('init',['locationsFilterGetter']),                         

    selectedDateFormat(){
        const monthNumber = moment( this.selectedDate._d ).month()
        if(this.currenMonthNumberDate !== monthNumber){
          return moment(this.selectedDate._d).format("01/MM/YYYY")
        }
        //si es el mes actual muestro la fecha actual
        if(this.currenMonthNumberDate === monthNumber ){
          return moment(this.selectedDate._d).format("DD/MM/YYYY")
        }        
    },
    selectedDateFormatInput(){
        return moment(this.selectedDate._d).format("YYYY/MM/DD") 
    },
    monthNameSelectedDate(){
        moment.locale('es')
        return moment(this.selectedDate._d).format('MMMM').toUpperCase()        
    },
    monthNumberSelectedDate(){        
        return moment( this.selectedDate._d ).month();
    }

  },
  methods: {
    moment,
    ...mapActions('init',['fetchLocationsScheduler']),
    ...mapActions('calendar',['fetchGroupsScheduler','getEventsAndTotals']),
    ...mapMutations('calendar',['setSelectedDate','setDaysMonth',]),
    ...mapMutations('init',['setSelectedHotelFilter','setSelectedLocationFilter']),

    async setSelectedDateToToday(){
        const date = moment()
        //regreso a la fecha original actual, mutacion
        this.setSelectedDate( date )  
        //regreso al panel del mes actual
        this.dateYYMM = date.format("YYYY-MM")          
        //vuelvo a realizar peticiones
        const days = await getDaysArray( date.year(), date.month() )
        this.setDaysMonth( days )        
        // store.commit('setLoading', true, { root: true} )            
        await this.getEventsAndTotals()//calendar module                  
    },
    async toggleSidebar( booleano ){
      this.visibleSidebar = !booleano      
      const sidebarGroups = document.getElementById("sidebarGroups")
      const calendarScheduler = document.getElementById("mainContent")
      if( this.visibleSidebar ){
        calendarScheduler.classList.remove("col-md-12")
        sidebarGroups.style.display = "block"        
        sidebarGroups.classList.add("col-md-3")
        calendarScheduler.classList.add("col-md-9")   
        if( !this.groups.length ){
          await this.fetchGroupsScheduler()
        }          
      } else {
        sidebarGroups.classList.remove("col-md-3")
        sidebarGroups.style.display = "none"
        calendarScheduler.classList.remove("col-md-9")
        calendarScheduler.classList.add("col-md-12")
      }                                        
    },
    openModalCreateGroup(){        
      this.$emit('openModalCreateGroup')
    },  
    async changeDate( date ){
      const myDate = moment(date) 
      this.setSelectedDate( myDate )      
      const days = await getDaysArray( myDate.year(), myDate.month() )
      this.setDaysMonth( days )           
      this.dateYYMM = myDate.format("YYYY-MM")
      // await this.fetchLocationsScheduler() //module init
      // store.commit('setLoading', true, { root: true} )
      // await this.fetchGroupsScheduler() //calendar module     
      await this.getEventsAndTotals()//calendar module 
      
    },
    async refreshGridEvents(){ 
      this.isRefreshGridEvents = true     
      await this.getEventsAndTotals()//calendar module 
      
      this.isRefreshGridEvents = false     
    },
    changeHotel(event){
      this.hotel = event.target.value
      this.setSelectedHotelFilter( this.hotel )            
    },
    changeLocation(event){
      this.location = event.target.value
      this.setSelectedLocationFilter( this.location )      
    }
  }
}
</script>