<template>
  <div>
    <!-- :title="eventTitle"  -->
    <b-sidebar id="sidebar-backdrop" right  :backdrop-variant="variant" backdrop shadow width="500px">
        <div>
            <b-container>
                <b-row class="mb-2">
                    <b-col>
                        <h5><strong>Locación: {{ nameLocation( selectedLocation ) }}</strong> </h5>
                        
                    </b-col>
                    <b-col>                        
                        <div class="float-right"><h5><strong>{{eventTitle}}</strong> </h5></div>
                    </b-col>
                </b-row>                
                <b-row>
                    <b-col> 
                                                                       
                        <b-button class="float-right" size="sm" variant="primary" @click="openModalCreateEvent">Nuevo evento</b-button>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col> 
                        <div class="text-center" v-if="isLoadingEvents">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando eventos en fecha seleccionada</strong>
                        </div>                   
                        <div v-if="eventsInSelectedDate.length && !isLoadingEvents">                            
                            <Event
                                v-for="(event, index) in eventsInSelectedDate" :key="index"
                                :event="event"
                                @edit-event="editEvento"
                                @delete-event="borrarEvento"
                            />
                        </div>                        
                        <b-alert show variant="danger" v-if="!eventsInSelectedDate.length && !isLoadingEvents">Aún sin eventos para esta fecha</b-alert>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </b-sidebar>

    <EditEvent
        v-if="selectedEvent"
        :visible="isOpenModalEditEvent"
        :selectedEvent="selectedEvent"         
        @closeModalEditEvent="closeModalEditEvent"
    />  

  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import * as moment from 'moment'
import EditEvent from '@/components/events/EditEvent'
import Event from '@/components/events/Event'

export default {
    name: 'SidebarDetails',
    components : {
        Event,
        EditEvent,
    },
    props:{        
        selectedDate: {
            type: Object,
            required: true
        }
    },    
    data() {
        return {
            loading: false,
            type:'success',
            hour: moment().hour(10).minute(0),
            content: '',
            variant: 'info',
            borders: {success: '#5cb85c'}            
        }
    },
    computed: {
        ...mapState('calendar', ['eventsInSelectedDate','selectedEvent','selectedLocation','isOpenModalEditEvent','isLoadingEvents','groups']),
        ...mapState('init', ['locations']),
        eventTitle(){
            return `Fecha: ${this.selectedDate.format('DD/MM/YYYY')}`
        }        
    },
    methods: {
        moment,      
        ...mapActions('calendar',['fetchGroupsScheduler','getEventsAndTotals','updateEvent','deleteEvent','fetchGroupById']),
        ...mapActions('init',['fetchLocationsScheduler']),
        ...mapMutations('calendar',['setSelectedEvent','unsetSelectedEvent','setVisibilityModalEditEvent',]),
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },
        openModalCreateEvent(){
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.$emit('openModalCreateEvent')
        },
        async editEvento( evento ){
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.setSelectedEvent(evento)
            this.setVisibilityModalEditEvent( true )                                             
            
            const payload = { idGroup: evento.grupo.id } 

            if( !this.groups.length ){                
                await this.fetchGroupById( payload ) // si state groups está vacío obtengo datos del grupo para ponerlos en state groups
            }
            if( this.groups.length ){
                const grupo = this.groups.find( group => group.id == payload.idGroup )
                if( !grupo ){ //sino se encuentra el grupo en el state groups
                    await this.fetchGroupById( payload ) // solicito al back la info por id que corresponda 
                }                                
            }
        },        
        closeModalEditEvent(){
            this.setVisibilityModalEditEvent(false)            
            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
            this.unsetSelectedEvent()
        },
        async borrarEvento( evento ){            
            const { id } = evento
            const removeIndex = this.eventsInSelectedDate.map(function(item) { return item.id; }).indexOf(id)
            this.eventsInSelectedDate.splice(removeIndex, 1)      
            await this.deleteEvent( evento )
            await this.getEventsAndTotals()//calendar module 
            
        }     
    },
}
</script>
<style>
.card-body {
    padding: .5rem!important;
}
.button-small{
    padding: 0.15rem 0.2rem!important;
}
</style>