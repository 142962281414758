<template>
  <div class="animate__animated animate__slideInLeft">
    <b-container>      
      <b-row class="mt-2 mb-2">
        <b-col>
          <SearchGroup/>
        </b-col>
      </b-row>
    </b-container>
    <div id="wrapper">
      <div class="scrollbar" id="styleSidebarGroups">
        <div class="force-overflow">
          <nav>
            <b-container>              
              <b-row class="mt-3">
                <b-col >
                  <div class="text-center" v-if="isLoadingGroups">							
                    <b-spinner label="Loading..." variant="success"/><br>
                    <strong>Cargando grupos</strong>
                  </div>

                  <div v-if="filteredGroupsGetter.length && !isLoadingGroups">
                    <Group v-for="(group, index) in filteredGroupsGetter"
                      :key="index" :group="group"
                      @edit-group="editGroup"
                      @edit-event="editEvento"
                      @delete-event="borrarEvento"
                    />
                  </div>
                  <b-alert show variant="danger" v-if="!isLoadingGroups && !filteredGroupsGetter.length">
                    <p>Sin grupos encontrados del mes actual, clic en el botón <br>
                    <span variant="success" size="sm"><b-icon icon="search"></b-icon></span><br>para hacer búsqueda en otros meses</p>
                        <!-- <b-icon icon="arrow-counterclockwise" animation="spin-reverse-pulse" font-scale="1"></b-icon>                       -->
                  </b-alert>                  
                </b-col>
              </b-row>        
            </b-container>   
          </nav>
        </div>
      </div>
    </div>      
      <EditGroup
        v-if="selectedGroup"
        :visible="visibleModalEditGroup"
        :selectedGroup="selectedGroup"
        @update-group="_updateGroup" 
        @close-modal-update-group="closeModalEditGroup" />
      
      <EditEvent
        v-if="selectedEvent"
        :visible="visibleModalEditEvent"
        :selectedEvent="selectedEvent"
        @update-event="updateEvento" 
        @closeModalEditEvent="closeModalEditEvent"
    /> 
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters} from 'vuex'
import * as moment from 'moment'
import EditGroup from '@/components/groups/EditGroup'
import EditEvent from '@/components/events/EditEvent'
import Group from '@/components/groups/Group'
import SearchGroup from '@/components/groups/SearchGroup'


export default {
  name: 'SidebarGroups',
  components : {
    Group,
    EditGroup,
    SearchGroup,
    EditEvent
  }, 
  props:{              
  },
  data() {
    return {  
      visibleModalEditGroup: false, //modal edit para mostrar/ocultar               
      visibleModalEditEvent: false, //modal edit para mostrar/ocultar               
    }
  },
  computed:{
    ...mapState('calendar', ['selectedDate','groups','selectedGroup','selectedEvent','isLoadingGroups']),
    ...mapGetters('calendar', ['filteredGroupsGetter']),   
    ...mapState('init', ['groupTypes','agencies','agents','coordinators','agencySelected']),  
  },
  methods: {
    moment,    
    ...mapActions('init',['fetchLocationsScheduler','fetchAgentsByIdAgency','fetchGroupTypes','fetchAgencies','fetchAgents','fetchCoordinators']),    
    ...mapActions('calendar',['updateGroup','updateEvent','deleteEvent','fetchGroupsScheduler','getEventsAndTotals']),    
    ...mapMutations('calendar',['setSelectedGroup','setSelectedEvent','unsetSelectedEvent',]),         
    ...mapMutations('init',['setAgencySelected']),            
    async editGroup( group ){
      const { agency } = group           
      
      this.openModalEditGroup()

      await this.setSelectedGroup( group )
      
      if( !this.groupTypes.length ){
        await this.fetchGroupTypes()
      }

      if( !this.agencies.length ){
        await this.fetchAgencies()
      }

      if( !this.agents.length && this.agencySelected ){
        await this.fetchAgents()
      }

      if( !this.coordinators.length ){
        await this.fetchCoordinators()
      }
                 
      if( agency ){
        await this.fetchAgentsByIdAgency( agency )
      }
    },
    async _updateGroup( data ){      
      if (!data.agency) {
        delete data.agency
      }
      if (!data.agentname) {
        delete data.agentname
      }
      if (!data.coordinador) {
        delete data.coordinador
      }
      if (!data.tipogrupo) {
        delete data.tipogrupo
      }
      if ( data.arrivaldate == "")  {
        data.arrivaldate = null        
      }
      if ( data.departuredate == "")  {
        data.departuredate = null        
      }
      // console.log(date)
      // console.log(data)
      await this.updateGroup( data )
      this.closeModalEditGroup()        
      await this.getEventsAndTotals()//calendar module 
               
    },
    openModalEditGroup(){
      this.visibleModalEditGroup = true            
    },
    closeModalEditGroup(){             
      this.visibleModalEditGroup = false            
    },
    closeModalEditEvent(){
        this.visibleModalEditEvent = false            
        this.unsetSelectedEvent()
    },
    async editEvento( evento ){
      this.visibleModalEditEvent = true                                  
      this.setSelectedEvent(evento)        
    },
    async updateEvento( data ){       
      if (!data.backup) {
        delete data.backup
      }
      if (!data.montaje) {
        delete data.montaje        
      }
        // console.log( data )       
      this.updateEvent( data )
      this.visibleModalEditEvent = true                                              
      this.unsetSelectedEvent()      
      await this.getEventsAndTotals()//calendar module 
      
    },
    async borrarEvento( evento ){                       
      await this.deleteEvent( evento )
      await this.getEventsAndTotals()//calendar module         
    }
  }
}
</script>

