export function locationsFilterGetter( state ) {

    const idHotel = state.selectedHotelFilter      
  

    if( idHotel === "all" ){
        return state.locations
    }         
    
    return state.locations.filter( location => location.hotel === parseInt(idHotel))
    
}