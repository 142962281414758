<template>
  <div id="app">     
    <Loading :active.sync="loading"></Loading>
    <router-view/>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {      
    Loading
  },
  props:{
    usr: {
      type: String,
      required: false,      
    }
  },
  mounted(){         
    if( this.usr ){
      this.setUser()
    }
  },
  computed:{                
    ...mapState(['loading']),            	
  }, 
  methods:{
  ...mapMutations('init',['setIdUser']),
  setUser(){
    const idUserEsNumero = !isNaN( this.usr )
      if( idUserEsNumero ){
        this.setIdUser( parseInt( this.usr ) )
      }
    }
  } 
}
</script>