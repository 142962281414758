<template>
  <b-container fluid>
    <b-row class="mt-2">         
      <div id="sidebarGroups">
        <SidebarGroups @openModalCreateGroup="openModalCreateGroup"/>
      </div>
      <div id="mainContent">
        <HeaderCalendar
          :selectedDate="selectedDate"
          :currentMonthName="currentMonthName"
          @change-sidebar-group-visible="changeSidebarGroupVisible"
          @openModalCreateGroup="openModalCreateGroup"          
        />

        <GridCalendar @add-event-in-location="addEvent" />

        <CreateEvent                  
            :visible="isOpenModalCreateEvent"
            :selectedDate="selectedDate"
            :selectedLocation="selectedLocation || 0"
            :selectedGroupE="selectedGroup || {name:null}"
            @change-selected-location="changeSelectedLocation"
            @set-selected-group="setSelectGroup"            
            @closeModalCreateEvent="closeModalCreateEvent"
          />
          
          <CreateGroup
            :visible="visibleModalCreateGroup"  
            :selectedDate="selectedDate"    
            @addNewGroup="addNewGroup"
            @closeModalCreateGroup="closeModalCreateGroup"
          />

          <SidebarEvents
            :selectedDate="selectedDate"
            @openModalCreateEvent="openModalCreateEvent"          
          />     
      </div>    
  </b-row> 
  </b-container>                  
</template>
<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import * as moment from 'moment'
import HeaderCalendar from '@/components/scheduler/HeaderCalendar'
import GridCalendar from '@/components/scheduler/GridCalendar'
import CreateEvent from '@/components/events/CreateEvent'
import CreateGroup from '@/components/groups/CreateGroup'
import SidebarGroups from '@/components/scheduler/SidebarGroups'
import SidebarEvents from '@/components/scheduler/SidebarEvents'
import { getDaysArray } from '@/helpers/helpers' 


export default {
  name: 'SchedulerEvents',
  components : {
    HeaderCalendar,
    GridCalendar,
    CreateEvent,
    CreateGroup,
    SidebarGroups,
    SidebarEvents
  },
  async mounted(){     
     const element1 = document.getElementById("sidebarGroups")
     const element2 = document.getElementById("mainContent")     
     element1.style.display = "none"
     element2.classList.add("col-md-12") 

    const days = await getDaysArray( moment().year(), moment().month() )      
    // console.log(    console.log( days[0].momentDate.year()))    
    this.setDaysMonth( days )
    await this.getInitialGridCalendar()   
  },
  // 2021-02-19 18:31:48
  data() {
    return {
      selectedDate: moment(),
      currentMonthName: moment().format('MMMM'),           
      visibleModalCreateGroup: false ,      
    }
  },
  computed:{
    ...mapState('calendar', ['days','isOpenModalCreateEvent','eventsData','selectedLocation','selectedGroup','groups',]),    
    ...mapState('init', ['groupTypes','agencies','agents','coordinators','agencySelected','idUser']),  
    ...mapGetters('init',['locationsFilterGetter']),                         
  },
  methods: {
    moment,
    ...mapActions('calendar',['fetchGroupsScheduler','saveGroup','fetchEventsByDateAndLocation','getInitialGridCalendar']),    
    ...mapActions('init',['fetchGroupTypes','fetchAgencies','fetchAgents','fetchCoordinators']),    
    ...mapMutations('calendar',['setDaysMonth','setSelectedDate','setSelectedLocation','setEventsInSelectedDate','setSelectedGroup','setVisibilityModalCreateEvent']), 
    async addEvent ( payload ) {        
      const { idLocation, date, dayNumber, numEvents } = payload
      this.selectedDate = moment( new Date(date.year(), date.month(), dayNumber) )
      this.setSelectedDate( this.selectedDate )      
      this.setSelectedLocation( idLocation )                              
      
      if( numEvents  != '' ){ 
        this.setEventsInSelectedDate([])       
        this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop')
        await this.fetchEventsByDateAndLocation()
      } else {
        this.openModalCreateEvent()                     
      }               
    },            
    async addNewGroup (data) {  
      if (typeof data.agency === 'undefined') {
        delete data.agency
      }
      if (typeof data.agentname === 'undefined') {
        delete data.agentname        
      }
      if (typeof data.coordinador === 'undefined') {
        delete data.coordinador        
      }
      if ( isNaN(data.tipogrupo) ) {
        delete data.tipogrupo        
      }
      if ( data.arrivaldate == "")  {
        data.arrivaldate = null        
      }
      if ( data.departuredate == "")  {
        data.departuredate = null        
      }
      this.closeModalCreateGroup()     
      await this.saveGroup( data )             
    },  
    changeSelectedLocation( location ){
      this.setSelectedLocation( parseInt(location) )                  
    },  
    async openModalCreateEvent(){
      this.setVisibilityModalCreateEvent( true )   
      if( !this.groups.length ){
        await this.fetchGroupsScheduler()
      }
    },
    async openModalCreateGroup(){
      this.visibleModalCreateGroup = true

      if( !this.groupTypes.length ){
        await this.fetchGroupTypes()
      }

      if( !this.agencies.length ){
        await this.fetchAgencies()
      }

      if( !this.agents.length && this.agencySelected ){
        await this.fetchAgents()
      }

      if( !this.coordinators.length ){
        await this.fetchCoordinators()
      }
      
    },
    closeModalCreateEvent() {
      this.setVisibilityModalCreateEvent( false )                  
    },
    closeModalCreateGroup() {
        this.visibleModalCreateGroup = false        
    },
    changeSidebarGroupVisible(booleano){      
        this.sidebarGroupVisible = booleano        
    },
    setSelectGroup( group ){
      this.setSelectedGroup( group )
    }
  },      
}
</script>
