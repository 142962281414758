<template>
    <antModal v-model="visible" :title="grupo.name" :closable="false" :maskClosable="false" :width="800" :centered="true" :footer="null" >        
        <b-row>
            <b-col>
                <strong> Hotel: {{ nameHotel( grupo.grouphotel ) }}</strong><br><br>

                <table class="table">
                    <thead class="thead-dark">
                        <tr style="font-size: .9em">
                            <th scope="col">Evento</th>                            
                            <th scope="col">Fecha hora inicio</th>
                            <th scope="col">Fecha hora fin</th>
                            <th scope="col">Locación</th>
                            <th scope="col">Locación Backup</th>
                            <th scope="col">Montaje</th>
                            <th scope="col">AD </th>
                            <th scope="col">NI </th>
                            <th scope="col">INF </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grup, index) in dataGrupo" :key="index" style="font-size: 1em">
                            <!-- <th scope="row">{{index + 1 }}</th>                             -->
                            <th scope="row">{{grup.nombreEvento }}</th>                            
                            <td>{{ grup.startDate }} {{ grup.startTime }} </td>
                            <td>{{ grup.endDate  }} {{ grup.endTime }}</td>
                            <td>{{ nameLocation( grup.locacion ) }}</td>
                            <td>{{ nameLocation( grup.backup ) }}</td>
                            <td>{{ grup.montaje ? grup.montaje.name : ''}}</td>                            
                            <td>{{grup.adult}} </td>
                            <td>{{grup.child}} </td>
                            <td> {{grup.infant}}</td> 
                            <!-- {{moment(`${grup.startDate} ${grup.startTime}`)}}                                                       -->
                        </tr>                      
                    </tbody>
                </table>                                
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div>
                    <b-button variant="danger" @click="closeModalDetails">Cerrar</b-button>
                </div>
            </b-col>                    
        </b-row>
    </antModal>
</template>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'

export default {
    name: 'ListEventsGroup',
    props:{
        visible: {
            type: Boolean,
            required: true
        },
        grupo:{
            type: Object,
            required: true
        }                
    },
    
    computed: {        
        ...mapState('init', ['hotels','locations']), 
        // ordenamiento de los datos del calendario del grupo for fecha startDate y startTime 
        dataGrupo(){               
            const array = this.grupo.calendario                        
            return array.sort(function(a,b){
                let fecha1 = new Date(`${b.startDate} ${b.startTime}`)
                let fecha2 = new Date(`${a.startDate} ${a.startTime}`)                                
                return  fecha2.getTime() - fecha1.getTime()
            })            
        }                                              
    },
    methods: {
        moment,
        closeModalDetails(){
            this.$emit('close-modal-list-events-group')
        },
        nameHotel( idHotel ){
            const item = this.hotels.find( hotel => hotel.id === idHotel )            
            return item ? item.name : ''
        },
        nameLocation( idLocation ){
            const item = this.locations.find( item => item.id === idLocation )            
            return item ? item.name : ''
        },                
    }
}
</script>