<template>
    <ValidationObserver ref="observer">                      
        <ValidationProvider rules="min:2" name="buscador">
            <b-input-group slot-scope="{ valid, errors }" >
                <b-form-input
                type="email"
                v-model="filter.query"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Buscar grupos">
                </b-form-input>
                 <b-input-group-append>                    
                    <b-button variant="success" @click="searchGroupByName(filter.query)"><b-icon icon="search"></b-icon></b-button>
                </b-input-group-append>
                <b-form-invalid-feedback>
                {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-input-group>
        </ValidationProvider>                        
    </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'SearchGroup',        
    computed:{
        ...mapState('calendar',['filter']),             
    },
    methods: {
        ...mapActions('calendar',['fetchGroupsSchedulerByName']),
        async searchGroupByName( nameGroup){
            if( nameGroup != ""){ 
                const payload =  { nameGroup }              
                await this.fetchGroupsSchedulerByName( payload )
            }
        }
    }
}
</script>



