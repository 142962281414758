require('./vue-axios')
require('./vue-sweetalert2')
require('./vee-validate')
require('./vue-ant-desing')
require('./bootstrap-vue')
require('./vue-select')
require('./vue-toast')



