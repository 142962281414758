import { render, staticRenderFns } from "./SidebarGroups.vue?vue&type=template&id=57a5c907&"
import script from "./SidebarGroups.vue?vue&type=script&lang=js&"
export * from "./SidebarGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports