<template>
  <div class="table-scroll mb-5" >

    <div class="text-center" v-if="isLoadingLocations">							
      <b-spinner label="Loading..." variant="success"/><br>
      <strong>Cargando locaciones</strong>
    </div>
    <!-- <pre>{{totalEventsByMonth}}</pre> -->
    <div v-if="!isLoadingLocations">
      
      <table v-if="locationsFilterGetter.length && eventsData">
        <thead>          
          <tr>
            <th >Locación / días</th>
            <th v-for="(day ) in days" :key="day.key" class="styleTds" :id="'tooltip-button'+day.key">
              <b-tooltip  :target="'tooltip-button'+day.key" placement="top">
              {{day.dayNameFull }} {{day.dayNumber }}: <strong>  {{getTotalEventsByDay(day.year, day.month, day.dayNumber).total }}</strong> eventos
            </b-tooltip>
              <div class="wrapperCell">                
                <div  class="badge-total-events a text-badges">
                  {{getTotalEventsByDay(day.year, day.month, day.dayNumber).total }}
                </div>           
                <div class="badge-info b text-badges">
                  {{day.dayName }}
                </div>
                <div class="badge-info c text-badges">                
                  {{day.dayNumber}}             
                </div> 
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(location, index) in locationsFilterGetter" :key="index" class="hoverTr">
            <th >{{location.name}}</th>
                    
              <td v-for="(day, index) in days" :key="index" class="text-center cursor-table"
                @click="addEvent(location.id, day.momentDate, day.dayNumber, getCountEventsInLocationByDay( location.id, day.year, day.month, day.dayNumber ).valor )"
                :style="getCountEventsInLocationByDay( location.id, day.year, day.month, day.dayNumber ).valor  != '' ? 'background:#e3f2fd': '' ">
                {{ getCountEventsInLocationByDay( location.id, day.year, day.month, day.dayNumber ).valor   }}                               
              </td>            
            </tr>                                        
        </tbody>            
      </table>
           
    </div>  
    <b-alert show variant="danger" v-if="!locationsFilterGetter.length" >
        <div class="alert-body text-center">
          😣<span>Hotel sin locaciones</span>
        </div>
      </b-alert>   
  </div>    
</template>
<script>
import * as moment from 'moment'
import { mapState,  mapMutations, mapGetters } from 'vuex'
import { getCountEventsInLocationByDay, getTotalEventsByDay } from '@/helpers/helpers' 
  export default {
    name: 'GridCalendar',
    computed:{
      ...mapState('calendar', ['days','eventsData','totalEventsByMonth']), 
      ...mapState('init', ['isLoadingLocations']),            
      ...mapGetters('init',['locationsFilterGetter']),                         
    },
    methods: {
      moment,
      ...mapMutations('calendar',['setDaysMonth']),       
      getCountEventsInLocationByDay,
      getTotalEventsByDay,
      async addEvent ( idLocation, date,  dayNumber, numEvents) {
        const payload = { idLocation, date,  dayNumber, numEvents }
        this.$emit('add-event-in-location', payload)                                
      }
    }
  }
</script>

<style scoped>
.styleTds{
  margin: 0%;
  padding: 0%;
  border-top: 4px solid rgb(241, 231, 231);
  border-bottom: 4px solid rgb(241, 231, 231);
  border-right: 2px solid rgb(241, 231, 231);
  border-left: 2px solid rgb(241, 231, 231);
  
}
.wrapperCell {
  color: white;  
  display: grid;      
  grid-template-columns: 1fr 1fr;  
  /* grid-template-areas: "totalEventos totalEventos" "day numberDay"; */
}
.a {
  grid-column-start: 1;
  grid-column-end: 3;        
}
.b {
  grid-row-start: 2;
}

.badge-info {
  background: #ffffff;
  color: #161515;
  font-size: .8rem;

}

.badge-total-events {
  background: #ced1cff7;
  color: #161515;
  font-size: .8rem;

} 
.text-badges {
  padding: 0.25em 0.4em;
  /* font-size: 85%; */
  /* font-weight: 700; */
  /* line-height: 1; */
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;    
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

</style>