<template>
  <div>    
    <antModal v-model="visible" :title="eventTitle" :closable="false" :maskClosable="false" :centered="true" :footer="null" :width="750">
        <ValidationObserver ref="form" tag="form">
            <b-form @submit.prevent="addEvent" >
                <b-row>
                    <b-col>
                        <ValidationProvider name="Locacion" rules="required">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Locación</strong></label>                            
                            <b-form-select
                                :state="errors[0] ? false : (valid ? true : null)"
                                :value="selectedLocation"
                                @change="onChangeLocation($event)"
                                disabled
                                id="locationEvent"  
                            >
                            <option v-for="(location, index) in locations" :key="index" :value="location.id">{{location.name}}</option>
                            </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="evento">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Nombre evento*</strong></label>                            
                            <b-input
                                placeholder="Ingrese nombre del evento"
                                v-model="evento"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>                                                
                        <ValidationProvider name="montaje" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Montaje</strong></label>                             
                            <v-select
                                @search:blur="blurSelectMontaje"
                                :options="computedMontajes"
                                label="name"
                                v-model="montaje"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <!-- {{computedMontajes}} -->
                    </b-col>
                    <b-col>
                        <ValidationProvider name="backup" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Backup</strong></label>                             
                            <v-select
                                @search:blur="blurSelectBackup"
                                :options="computedBackups"
                                label="name"
                                v-model="backup"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <div class="text-center" v-if="isLoadingGroups">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando grupos </strong>
                        </div>                         
                        <ValidationProvider name="grupo" rules="required" v-if="!isLoadingGroups">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Grupo*</strong></label>                             
                            <v-select
                                :filterable="false"                                                          
                                @search="blurSelectGroup"
                                @input="_setGroup"  
                                :options="groups"
                                label="name"
                                :value="selectedGroupE"                                
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                                       
                    </b-col>
                </b-row>                
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="Fecha llegada">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha inicio*</strong></label>                 
                            <b-input
                                id="dateStart"
                                type="date"                                                        
                                :value="valueFechaInicio"                                
                                :state="errors[0] ? false : (valid ? true : null)"    
                                readonly                            
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label> <strong>Hora inicio*</strong></label><br>
                            <antTimePicker
                                :minuteStep="5"
                                format="HH:mm"
                                :value="moment(startHour,'HH:mm')"
                                :defaultValue="moment('10:00','HH:mm')"
                                @change="onHourChangeInit"
                            ></antTimePicker>
                        </b-form-group>
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="Fecha fin">
                        <b-form-group slot-scope="{ valid, errors }">
                
                            <label> <strong>Fecha fin*</strong></label>                   
                            <b-input
                                id="dateEnd"
                                type="date"
                                :value="valueFechaEnd"                                 
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label> <strong>Hora fin*</strong></label><br>
                            <antTimePicker
                                @change="onHourChangeEnd"
                                :minuteStep="5"
                                :value="moment(endHour,'HH:mm')"
                                :defaultValue="moment('11:00','HH:mm')"
                                format="HH:mm"
                            ></antTimePicker>
                        </b-form-group>
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="Adultos">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Adultos*</strong></label>
                            <b-input type="number" placeholder="Número de adultos" v-model="adults" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Niños*</strong></label>
                            <b-input type="number" placeholder="Número de niños" v-model="children" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Infantes*</strong></label>
                            <b-input type="number" placeholder="Número de infantes" v-model="infants" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                    
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button variant="danger" @click="closeModalCreateEvent">Cerrar</b-button>
                    </b-col>
                    <b-col>
                        <!-- :disabled="invalid" -->
                        <b-button type="submit" variant="success" class="float-right" >Crear evento</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>                
    </antModal>
  </div>
</template>
<script>
import * as moment from 'moment'
import Vue from 'vue'
import { mapActions, mapState, mapMutations} from 'vuex'
export default {
    name: 'CreateEvent',
    props:{
        visible: {
            type: Boolean,
            required: true
        },
        selectedDate: {
            type: Object,
            required: true
        },
        selectedLocation: {
            type: Number,
            required: true
        },
        selectedGroupE: {
            type: Object,
            required: true
        }
    },
    mounted(){
        // console.log(this.location)
    },
    data() {
        return {
            loading: false,               
            montaje: "", 
            backup:"",            
            evento: "",
            startHour: moment().hour(10).minute(0),
            endHour: moment().hour(11).minute(0),   
            adults: 1,
            children: 0,
            infants: 0,                           
        }
    },
    computed: {
        ...mapState('init', ['locations','events','selectedGroup','idUser']),                    
        ...mapState('calendar', ['groups','isOpenModalCreateEvent','isLoadingGroups']),   
        eventTitle(){
            return `Crear evento para el dia ${this.selectedDate.format('DD/MM/Y')}`
        },
        valueFechaInicio(){
            return `${this.selectedDate.format('Y-MM-DD')}`
        },
        valueFechaEnd(){
            return `${this.selectedDate.format('Y-MM-DD')}`
        },
        computedFormCreateEvent(){
            return this.$refs.form
        },
        groupSelectedCreate: {
            get(){                
                return this.groups.find( group => group.id === this.selectedGroupE )               
            },
            set( grupo ){
                const id  = grupo ? grupo.id : null
                return this.groups.find( group => group.id === id )             
           } 
        },        
        computedMontajes(){
            const location = this.locations.find( location => location.id === parseInt(this.selectedLocation))
            return location.montaje ? location.montaje : []                     
        },
        computedBackups(){
            const location = this.locations.find( location => location.id === parseInt(this.selectedLocation))
            return location.backup ? location.backup : []                     
        }
    },
    methods: {
        moment,
        ...mapActions('calendar',['fetchGroupsSchedulerByName','validateEvent','getEventsAndTotals','fetchGroupsScheduler']),
        ...mapMutations('calendar',['setSelectedLocation',]), 
        async addEvent(){            
            const fechaInicio = document.getElementById('dateStart').value
            const fechaFin = document.getElementById('dateEnd').value
            const isValidDates = moment(fechaFin).isSameOrAfter(fechaInicio, 'day')
                    
            const success = await this.computedFormCreateEvent.validate()
            if (!success) {
                return
            }  
            if( !this.selectedGroupE.id ){                
                Vue.$toast.open({
                    message: `<strong>Debes seleccionar un grupo</strong>`,
                    type: 'error',
                    position:'top-right'
                })
                return
            }  
            if( !isValidDates ){
                Vue.$toast.open({
                    message: `<strong>Fecha fin no debe ser menor a fecha de inicio</strong>`,
                    type: 'error',
                    position:'top-right'
                })
                return
            }
            const eventoData = {
                createdby: this.idUser,
                idLoc: this.selectedLocation, //para validar en /grupos/validacion/ 
                fini: fechaInicio, //para validar en /grupos/validacion/ 
                hini: this.startHour.format("HH:mm"), //para validar en /grupos/validacion/ 
                ffin: fechaFin, //para validar en /grupos/validacion/ 
                hfin: this.endHour.format("HH:mm"), //para validar  /grupos/validacion/              
                locacion: this.selectedLocation,
                nombreEvento: this.evento,
                montaje: this.montaje.id,
                backup: this.backup.id,
                grupo: this.selectedGroupE.id,                
                startDate: fechaInicio,
                endDate: fechaFin,
                startTime: this.startHour.format("HH:mm"),
                endTime: this.endHour.format("HH:mm"), 
                adult: parseInt(this.adults),
                child: parseInt(this.children),
                infant:  parseInt(this.infants)
            }  
            this.saveEvent( eventoData )                                
        },
        async saveEvent( data ){
            if (typeof data.backup === 'undefined') {
                delete data.backup
            }
            if (typeof data.montaje === 'undefined') {
                delete data.montaje        
            }                        
            await this.validateEvent( data ) //valida y guarda   
            if( !this.isOpenModalCreateEvent ){ 
                Object.assign(this.$data, this.$options.data() ) 
                this.$refs.form.reset()        
                await this.getEventsAndTotals()//calendar module                     
            }
        },        
        closeModalCreateEvent(){
            Object.assign(this.$data, this.$options.data() ) 
            this.$refs.form.reset()  
            this.$emit('closeModalCreateEvent')
        },
        onChangeLocation(event){                        
            this.$emit('change-selected-location', event)            
        },  
        blurSelectEvent(){            
            this.computedFormCreateEvent.refs.evento.validate()
        },
        blurSelectMontaje(){            
            this.computedFormCreateEvent.refs.montaje.validate()
        }, 
        blurSelectBackup(){            
            this.computedFormCreateEvent.refs.backup.validate()
        }, 
        async blurSelectGroup(search, loading){
            const nameGroup = search              
            if( search.length == 5) {
                loading(true)
                const payload =  { nameGroup }              
                await this.fetchGroupsSchedulerByName( payload )
                loading(false)                
            }          
        },       
        onHourChangeInit( hour ){
            if( hour && hour !== ''){
                this.startHour = hour               
            }
        },
        onHourChangeEnd( hour ){
            if( hour && hour !== ''){
                this.endHour = hour               
            }
        },                
        _setGroup( group ){             
            this.$emit('set-selected-group', group)                        
        }
    },
}

</script>
