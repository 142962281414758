import Vue from 'vue'
import * as moment from 'moment'
import store from '../../store/index'
import { fetchHotels, fetchLocationsScheduler } from '../init/actions'
import { seedTotal } from './../../data/monthsYears'
// import { numberDayFromRangeDates } from "../../helpers/helpers";


export async function getInitialGridCalendar( context ){      
    context.commit('init/setLoadingLocations', true, { root: true })   
    const content = await Promise.all([        
        fetchHotels(), fetchLocationsScheduler(), fetchEventsScheduler(context), fetchTotalEventsByMonth(context), //make 4 requests
    ])    
    const [ hotels, locations, events, totals ] = content  
   
    context.commit('init/setHotels', hotels, { root: true }) //muto state hotels en init   
    context.commit('init/setLocationsData', locations, { root: true }) //muto state locations en init      
    context.commit('calendar/setEventsData', events, { root: true }) //muto state eventsData en start           
    context.commit('calendar/setTotalEventsByMonth', totals, { root: true }) //muto state totalEventsByMonth en calendar
    context.commit('init/setLoadingLocations', false, { root: true })   

    return content
}

export async function getEventsAndTotals( context ){           
    const content = await Promise.all([        
        fetchEventsScheduler(context), fetchTotalEventsByMonth(context), //make 2 requests
    ])    
    const [ events, totals ] = content              
    context.commit('calendar/setEventsData', events, { root: true }) //muto state eventsData en start           
    context.commit('calendar/setTotalEventsByMonth', totals, { root: true }) //muto state totalEventsByMonth en calendar
    return content
}

export async function fetchEventsScheduler(context){
    
    const fecha = context.rootState.calendar.selectedDate                                              
    const startDate = moment(fecha._d).format("YYYY-MM-01")
    const endDate = moment(fecha._d).endOf('month').format("YYYY-MM-DD")     
    try {
        const response = await Vue.axios({
            url:`/grupos/calendario/` ,
            params: {
                summary:1,
                fini: startDate,
                ffin: endDate
            } 
        })
        const payload = response && response.data   
        return payload
             
    } catch (error) {        
        console.log(error)
    } finally {
        console.log('fetchEventsScheduler ha terminado (calendar)')
    }
}

export async function fetchTotalEventsByMonth(context){
    
    const fecha = context.rootState.calendar.selectedDate                                              
    const startDate = moment(fecha._d).format("YYYY-MM-01")
    const endDate = moment(fecha._d).endOf('month').format("YYYY-MM-DD")   
    // const diffInDays = numberDayFromRangeDates(startDate, endDate)
          
    try {
        const response = await Vue.axios({
            url:`/grupos/calendariototal/` ,
            params: {
                idmont:6,
                fini: startDate,
                ffin: endDate
            } 
        })
        const payload = response && response.data   
        return payload
             
    } catch (error) {        
        console.log(error)
    } finally {
        console.log('fetchTotalEventsByMonth ha terminado (calendar)')
    }
}


export async function fetchEventsByDateAndLocation(context){
    
    const fecha = context.rootState.calendar.selectedDate                                              
    const idLocation = context.rootState.calendar.selectedLocation                                              
    const startDate = moment(fecha._d).format("YYYY-MM-DD")
    
    try {        
        context.commit('setLoadingEvents', true)
        const response = await Vue.axios({            
            url:`/grupos/calendario/` ,
            params: {
                loc: idLocation,
                fini: startDate,
                ffin: startDate
            } 
        })        
        const payload = response && response.data   
        context.commit('setEventsInSelectedDate', payload )               
    } catch (error) {        
        console.log(error)    
    } finally {
        context.commit('setLoadingEvents', false)
        console.log('La petición para obtener la lista de eventos del scheduler se ha terminado (calendar)')
    }
}

export async function fetchGroupsScheduler(context){     
    const fecha = context.rootState.calendar.selectedDate                                              
    const startDate = moment(fecha._d).format("YYYY-MM-01")
    const endDate = moment(fecha._d).endOf('month').format("YYYY-MM-DD") 
    try {
        context.commit('setLoadingGroups', true)
        const response = await Vue.axios({ 
            url:`/grupos/grupo/`,
            params: {
                fini: startDate,
                ffin: endDate
            }  
        })
        const payload = response && response.data        
        context.commit('setGroupsData', payload )                       

    } catch (error) {        
        console.log(error )
    } finally {
        context.commit('setLoadingGroups', false)
        console.log('La petición para obtener la lista de grupos del scheduler se ha terminado (calendar)')
    }
}
export async function fetchGroupsSchedulerByName(context, params){
    let groups = context.rootState.calendar.groups          
    const { nameGroup } = params         
    try {
        
        const response = await Vue.axios({ 
            url:`/grupos/grupo/`,
            params: {
                name: nameGroup                
            }  
        })
        const payload = response && response.data 
        payload.forEach( ( grupo ) => {
            groups.unshift(grupo)              
        })     
        const uniqueGroups = [...new Map(groups.map( group => [group.id, group] )).values()]    
        context.commit('setGroupsData', uniqueGroups )             
    } catch (error) {        
        context.commit('setCalendarError', error.message)
    } finally {    
        console.log('La petición para obtener grupos por nombre se ha terminado (calendar)')
    }
}

export async function fetchGroupById(context, params){
    const groups = context.rootState.calendar.groups          
    const { idGroup } = params         
    try {      
        context.commit('setLoadingGroups', true)
        const response = await Vue.axios({ 
            url:`/grupos/grupo/`,
            params: {
                idg: idGroup                
            }  
        })
        const payload = response && response.data 
        groups.unshift(payload[0]) // como viene array obtengo el primer objeto                          
        const uniqueGroups = [...new Map(groups.map( group => [group.id, group] )).values()]    
        context.commit('setGroupsData', uniqueGroups )             
    } catch (error) {        
        console.log(error )        
    } finally {    
        context.commit('setLoadingGroups', false)
        console.log('La petición para obtener grupos por id se ha terminado (calendar)')
    }
}

export async function saveGroup({commit}, group) {
    let groups = store.state.calendar.groups        
    try {         
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/grupo/`,
            data: group
        })  
        const respuesta = response && response.data 
        const { status } = respuesta
        if( status ){   
            const { grupo } = respuesta
            groups.unshift( grupo )                                     
            commit('setGroupsData', groups )             
            commit('setSelectedGroup', grupo )             
            Vue.$toast.open({
                message: `!Se ha guardo la información!`,
                type: 'success',
                position:'top-right'
            })             
        } else {
            Vue.$toast.open({
                message: `Ocurrió un error con el guardado`,
                type: 'error',
                position:'top-right'
            }) 
        }
        
    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {        
        console.log('La petición para guardar el grupo finalizó (calendar)')
    }
}

export async function updateGroup({commit}, group) { 
    const { id } = group       

    try {         
        const response = await Vue.axios({
            method:'PUT',                        
            url:`/grupos/grupo/${id}/`,
            data: group
        })  
        const respuesta = response && response.data 
        const { status } = respuesta
        if( status ){ 
            const { grupo } = respuesta
            // groups.unshift( grupo )                                     
            // commit('setGroupsData', groups )             
            commit('setSelectedGroup', grupo )            
            Vue.$toast.open({
                message: `!Se ha actualizado el grupo!`,
                type: 'success',
                position:'top-right'
            })             
        } else {
            Vue.$toast.open({
                message: `Ocurrió un error con la actualización del grupo`,
                type: 'error',
                position:'top-right'
            }) 
        }     
    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {        
        console.log('La petición para actualizar el grupo finalizó (calendar)')
    }
}

export async function validateEvent({commit}, evento) {     
    try { 
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/validacion/`,
            data: evento
        })          
        const respuesta = response && response.data         
        const { status } = respuesta
                
        if( status ){ 
            // console.log(status, evento)
            await saveEvent({commit}, evento) 
        } else {
            // console.log(status, respuesta)
            const { nombreevento, nombregrupo } = respuesta                           
            Vue.$toast.open({
                message: `<strong>No se puede hacer el guardado, locación ocupada por el grupo ${nombregrupo} con el evento ${nombreevento} </strong>`,            
                type: 'error',
                position:'top-right',
                duration: 5000
            })
        }
    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {  
        console.log('La petición para disponibilidad de locación para el evento finalizó (calendar)')
    }
}

export async function saveEvent({commit}, event) { 
    // console.log(event) 
    try {                  
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/calendario/`,
            data: event
        })  
        const respuesta = response && response.data 
        const { status } = respuesta
        if( status ){ 
            commit('setVisibilityModalCreateEvent', false )            
            Vue.$toast.open({
                message: `!Se ha guardo la información!`,
                type: 'success',
                position:'top-right'
            })             
        } else {
            Vue.$toast.open({
                message: `Ocurrió un error con el guardado`,
                type: 'error',
                position:'top-right'
            }) 
        } 

    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {  
        console.log('La petición para guardar el evento finalizó (calendar)')
    }
}

export async function validateEventUpdate({commit}, evento) {     
    try { 
        const response = await Vue.axios({
            method:'POST',                        
            url:`/grupos/validacion/`,
            data: evento
        })          
        const respuesta = response && response.data         
        const { status } = respuesta                      
        if( status ){ 
            await updateEvent({commit}, evento) 
        } else {
            commit('setVisibilityModalEditEvent', true )            
            const { nombreevento, nombregrupo } = respuesta             
            Vue.$toast.open({
                message: `<strong>No se puede hacer la actualización, locación ocupada por el grupo ${nombregrupo} con el evento ${nombreevento} </strong>`,
                type: 'error',
                position:'top-right',
                duration: 5000
            })
        }

    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {  
        console.log('La petición para disponibilidad de locación para actualizar evento finalizó (calendar)')
    }
}

export async function updateEvent({commit}, evento) {   
    const { id } = evento       
    try {         
        const response = await Vue.axios({
            method:'PUT',                        
            url:`/grupos/calendario/${id}/`,
            data: evento
        })  
        const respuesta = response && response.data         
        const { status } = respuesta

        if( status ){  
            commit('setVisibilityModalEditEvent', false )            
            Vue.$toast.open({
                message: `!Se ha actualizado correctamente!`,
                type: 'success',
                position:'top-right'
            })             
        } else {
            Vue.$toast.open({
                message: `Ocurrió un error con la actualización`,
                type: 'error',
                position:'top-right'
            }) 
        }

    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {        
        console.log('La petición para actualizar el evento finalizó (calendar)')
    }
}

export async function deleteEvent({commit}, evento) {
    const { id } = evento       
    try {         
        const response = await Vue.axios({
            method:'DELETE',                        
            url:`/grupos/calendario/${id}/`,                        
        })  
        const respuesta = response && response.data 
        const { status } = respuesta

        if( status ){            
            Vue.$toast.open({
                message: `!Se ha borrado correctamente!`,
                type: 'success',
                position:'top-right'
            })             
        } else {
            Vue.$toast.open({
                message: `Ocurrió un error con el borrado`,
                type: 'error',
                position:'top-right'
            }) 
        }
    } catch (error) {
        commit('setCalendarError', error.message)
    } finally {        
        console.log('La petición para borrar el evento finalizó (calendar)')
    }
}