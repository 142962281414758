import * as moment from 'moment'
import store from '../store/index'

const handlerErrors = ( errorMessage, errorResponse ) => {    
    let stringError = ''
    const responseStatus =  errorResponse.status || false 
    const responseStatusText = errorResponse.statusText || false 
    const responseStatusDataDetail = errorResponse.data.detail || false 

    stringError += '<strong>Error message:</strong> ' + errorMessage + '<br>'

    if( responseStatus ){
        stringError += '<strong>Error code:</strong> ' +responseStatus +'<br>'
    }

    if( responseStatusText ){
        stringError += '<strong>Status text:</strong> ' +responseStatusText +'<br>'
    }

    if( responseStatusDataDetail ){
        stringError += '<strong>Detail:</strong> ' +responseStatusDataDetail +'<br>'
    }

    return stringError
}

const getDaysArray = (year, month) => {    
    const names = Object.freeze( [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ])    
    const namesFull = Object.freeze( [ 'Domingo', 'Lunes', 'Miércoles', 'Miercoles', 'Jueves', 'Viernes', 'Sabado' ])    

    const date = new Date(year, month, 1)
    const result = []
    while ( date.getMonth() == month ) { 
      const key = Math.random().toString(36).substring(2,9)
      const momentDate = moment( date ) 
      const year = momentDate.year()     
      const month = momentDate.month()     

      result.push( {key, dayNumber: `${date.getDate()}`, month, year, dayName: `${names[date.getDay()]}`, momentDate, dayNameFull: `${namesFull[date.getDay()]}` })
      date.setDate(date.getDate() + 1)
    }
    return result
}

const getCountEventsInLocationByDay = (idLocation, year, month, day) => {
    const formatDate = moment( new Date(year, month, day) ).format("YYYY-MM-DD")  
    const events = store.state.calendar.eventsData         
    const event = events.find( event => event.locacion == idLocation && event.fecha == formatDate )
    return event || { fecha: formatDate, locacion: idLocation, valor: '' }    
    
}

const getTotalEventsByDay = ( year, month, day) => {
    const formatDate = moment( new Date(year, month, day) ).format("YYYY-MM-DD")  
    const totalEvents = store.state.calendar.totalEventsByMonth         
    const total = totalEvents.find( total =>  total.fecha == formatDate )
    return total || { fecha: formatDate, total: 0 }    
    
}

const countEventsInLocationByDay = (idLocation, date, events) => {
    if(events){
        const resultProductData = events.filter(function (item) {
            const date1 = moment(new Date(item.startDate))
            const date2 = moment(new Date(item.endDate))
            return ( moment(new Date(date)) >= date1 && moment(new Date(date)) <= date2 && item.locacion === idLocation)
        })           
        return resultProductData
    }   
}

const countEventsInBackupLocationByDay = (idLocation, date, events) => {  
    if( events ){
        const resultProductData = events.filter(function (item) {
            const date1 = moment(new Date(item.startDate))
            const date2 = moment(new Date(item.endDate))
            return ( moment(new Date(date)) >= date1 && moment(new Date(date)) <= date2 && item.backup === idLocation)
          })           
        return resultProductData
    } 
}

const eventsInLocationAndBackupLocation = ( eventosLocation, eventosLocationBack ) => {
    return eventosLocation.concat(eventosLocationBack)
    // return [...new Map(eventosLocation.map( evento => [evento.id, evento] )).values()]    
}

const yearsMinMax = () =>  {
    const yearMin = moment().subtract(5, 'years').year()
    const yearMax = moment().add(5, 'years').year()
    return { yearMin, yearMax}
}
//regreso los eventos de un grupo en especifico
const findEventsInGroup = (group, events ) => {
    return events.filter( item => item.grupo === group.id )    
}

const dataGroup = ( idGroup ) => {
    const groups = store.state.calendar.groups 
    const group = groups.find( group => group.id === parseInt(idGroup) )
    return group || null
}
const makeGridCalendar = () => {
    const daysHeader = []
    const daysLocations = []

    const days = store.state.calendar.days 
    const locations = store.state.init.locations 

    days.forEach( ({ dayName, dayNumber }) => {                 
        daysHeader.push( {dayName, dayNumber:parseInt(dayNumber) }  )          
    }) 
    locations.forEach( ({ id, name }) => {                 
        daysLocations.push( {idLocation: id, name, daysHeader }  )          
    }) 

    const gridCalendar = {
        daysHeader,
        daysLocations
    }
    store.commit("calendar/setGridCalendar", gridCalendar )    
}

const capitalizeFirstLetterString = ( string ) => {
    const cadena = string.toLowerCase()
    const arr = cadena.split(" ") 
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    
    }
    return arr.join(" ")
}


const formatEvents = ( events ) => {    
    const listEvents = []
    events.forEach( ({ startDate, endDate, locacion, nombreEvento }) => {                 
        listEvents.push( {startDate, endDate, locacion, nombreEvento }  )          
    }) 
    return listEvents
}
const numberDayFromRangeDates = (start, end ) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
     // One day in milliseconds
     const oneDay = 1000 * 60 * 60 * 24;
     // Calculating the time difference between two dates
     const diffInTime = date2.getTime() - date1.getTime();
     // Calculating the no. of days between two dates
     return  Math.round(diffInTime / oneDay) + 1  
}

export { 
    handlerErrors,
    getDaysArray,
    countEventsInLocationByDay,
    countEventsInBackupLocationByDay,
    eventsInLocationAndBackupLocation,
    yearsMinMax,
    findEventsInGroup,
    dataGroup,
    makeGridCalendar,
    formatEvents,
    getCountEventsInLocationByDay,
    capitalizeFirstLetterString,
    numberDayFromRangeDates,
    getTotalEventsByDay    
}