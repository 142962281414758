<template>
  <div>
    <SchedulerEvents v-if="idUser"/>
    <div id="notfound" v-if="!idUser">
      <div class="notfound">
        <div class="notfound-404">
          <h1>Oops!</h1>
        </div>
        <h2>403 - Forbidden Access </h2>
        <p>You don't have permission to access / on this page.</p>           
      </div>
    </div>   
  </div>
</template>
<script>
import {mapState } from 'vuex'
import SchedulerEvents from '@/components/scheduler/SchedulerEvents' 
import Forbidden from '@/components/403/Forbidden' 


export default {
  components: {            
    SchedulerEvents,
    Forbidden
  },
  mounted(){       

  },
  computed:{
    ...mapState('init',['idUser']),
    existsIdUser(){
      return isNaN( this.usr )
    }
  },
  methods:{
              
  }
}
</script>
<style>
  @import '~@/assets/css/styles.css';  /*estilos */     
</style>