<template>
  <div>    
    <antModal v-model="visible" :title="eventTitle" :closable="false" :maskClosable="false" :width="750" :centered="true" :footer="null" v-if="selectedGroup">        
        <ValidationObserver  ref="observer" tag="form">            
            <b-form @submit.prevent="updateGroup">                
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="nombre grupo">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Nombre grupo*</strong></label>                                         
                            <b-input placeholder="Nombre del grupo" v-model="selectedGroup.name" :state="errors[0] ? false : (valid ? true : null)" ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        
                        <ValidationProvider rules="required" name="clasificacion grupo" >
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Clasificación grupo*</strong></label>                            
                            <b-input
                                placeholder="clasificación del grupo"
                                v-model="selectedGroup.clasificacion"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                    <b-col> 
                        <div class="text-center" v-if="isLoadingGroupTypes">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando tipos </strong>
                        </div>                                               
                        <ValidationProvider name="grupo" rules="required" v-if="!isLoadingGroupTypes">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Tipo grupo*</strong></label> 
                            <v-select 
                                @search:blur="blurSelectEditGroupType"
                                @input="_setGroupType"                               
                                :options="groupTypes"
                                label="tipogruponame"
                                v-model="groupTypeEdit"                        
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider> 
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="appellido contacto">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Apellido del contacto*</strong></label>                                         
                            <b-input placeholder="Apellido del contacto" v-model="selectedGroup.contact" :state="errors[0] ? false : (valid ? true : null)" ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="telefono del contacto">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Telefono del contacto*</strong></label>                            
                            <b-input
                                placeholder="Télefono"
                                v-model="selectedGroup.phonecontact"
                                @keypress="formatPhoneNumber" 
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="correo electrónico">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Correo electrónico*</strong></label>                            
                            <b-input
                                type="email"
                                placeholder="email"
                                v-model="selectedGroup.emailcontact"
                                @keypress="checkIfFormatEmailIsValid" 
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            <span style="color:red;"> {{ checkIfFormatEmailIsValid( selectedGroup.emailcontact ) }}</span>

                        </b-form-group>
                        </ValidationProvider>                       
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="" name="Wedding ID">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Wedding Id</strong></label><br>
                            <b-input placeholder="Ingrese Wedding Id" v-model="selectedGroup.weddingID" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Fecha llegada">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha llegada</strong></label><br>                   
                            <b-input                            
                                id="dateArrivalEdit"
                                type="date"                                                        
                                v-model="selectedGroup.arrivaldate"
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Fecha Salida">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Fecha salida</strong></label><br>                   
                            <b-input
                                id="dateDepartureEdit"
                                type="date"                        
                                v-model="selectedGroup.departuredate"
                                :state="errors[0] ? false : (valid ? true : null)"                                
                            ></b-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>                        
                    </b-col>            
                </b-row>
                <b-row>
                    <b-col>
                        <ValidationProvider rules="required" name="Adultos">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Adultos</strong></label>
                            <b-input type="number" placeholder="Número de adultos" v-model="selectedGroup.adult" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Niños</strong></label>
                            <b-input type="number" placeholder="Número de niños" v-model="selectedGroup.child" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>
                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="required" name="Niños">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Infantes</strong></label>
                            <b-input type="number" placeholder="Número de infantes" v-model="selectedGroup.infant" :state="errors[0] ? false : (valid ? true : null)"></b-input>                            
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                    
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>                        
                        <ValidationProvider name="hoteles" rules="required">
                            <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Hotel</strong></label>                            
                            <b-form-select 
                                :state="errors[0] ? false : (valid ? true : null)"                                
                                v-model="selectedGroup.grouphotel">
                                <option value="">Seleccione hotel</option>
                                <option
                                    v-for="(hotel, index) in hotels"
                                    :key="index" :value="hotel.id"
                                >
                                {{hotel.name}}
                                </option>
                            </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider> 
                    </b-col>
                    <b-col>    
                        <div class="text-center" v-if="isLoadingAgencys">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando agencias </strong>
                        </div>                    
                        <ValidationProvider name="agencia" rules="" v-if="!isLoadingAgencys">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Agencia</strong></label> 
                            <v-select 
                                @search:blur="blurSelectEditAgencia"
                                @input="_setAgencySelected"                               
                                :options="agencies"
                                label="name"
                                v-model="agencyEdit"                        
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col> 
                    <b-col>
                        <ValidationProvider name="agente" rules="">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Agente</strong></label> 
                            <v-select 
                                @search:blur="blurSelectEditAgente"
                                @input="_setAgent"                               
                                :options="agents"
                                label="name"
                                v-model="agentEdit"                        
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>                       
                </b-row>
                <b-row>
                    <b-col>       
                         <div class="text-center" v-if="isLoadingCoordinators">							
                            <b-spinner label="Loading..." variant="success"/><br>
                            <strong>Cargando coordinadores </strong>
                        </div>
                 
                        <ValidationProvider name="coordinador" rules="" v-if="!isLoadingCoordinators">
                            <b-form-group slot-scope="{ errors }">
                            <label> <strong>Coordinador</strong></label> 
                            <v-select 
                                @search:blur="blurSelectEditCoordinator"
                                @input="_setCoordinator"                               
                                :options="coordinators"
                                label="name"
                                v-model="coordinatorEdit"                        
                                :class="{'is-invalid': !!errors.length}">
                            </v-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>                        
                    </b-col>
                    <b-col>
                        <ValidationProvider rules="" name="Descripción">
                        <b-form-group slot-scope="{ valid, errors }">
                            <label> <strong>Descripción</strong></label>
                            <b-form-textarea
                                id="textareaDescripcion"                        
                                placeholder="Breve descripción" 
                                v-model="selectedGroup.description" 
                                :state="errors[0] ? false : (valid ? true : null)"                     
                            ></b-form-textarea>                                                     
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>                        
                        </ValidationProvider>                        
                    </b-col>                                         
                </b-row>
                <b-row>
                    <b-col>
                        <b-button variant="danger" @click="closeModalUpdateGroup">Cerrar</b-button>
                    </b-col>
                    <b-col>
                        <!-- :disabled="invalid" -->
                        <b-button type="submit" variant="success" class="float-right" >Actualizar grupo</b-button>
                    </b-col>
                </b-row>
            </b-form>                
        </ValidationObserver>                                                         
    </antModal>
  </div>
</template>
<script>

import Vue from 'vue'
import * as moment from 'moment'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'EditGroup',
    props:{
        visible: {
            type: Boolean,
            required: true
        }, 
        selectedGroup: {
            type: Object,
            required: true
        },       
    },
    mounted(){        
    },
    data() {
        return {
            loading: false, 
            nombreGrupo: this.selectedGroup.name,
            hotelGrupo: this.selectedGroup.grouphotel,
            fechaLlegadaGrupo: this.selectedGroup.arrivaldate,
            fechaSalidaGrupo: this.selectedGroup.departuredate,
            adultosGrupo: this.selectedGroup.adult,
            niniosGrupo: this.selectedGroup.child,
            infantesGrupo: this.selectedGroup.infant,
            coordinadorGrupo: this.selectedGroup.coordinador,
        }
    },    
    computed: {
        ...mapState('calendar', ['locations']),        
        ...mapState('init', ['idUser','groupTypes','hotels','agencies','agents','coordinators','agencySelected','isLoadingGroupTypes','isLoadingCoordinators','isLoadingAgencys']),                    
        eventTitle(){            
            return `Actualizar grupo`
        }, 
        computedFormEditGroup(){
            return this.$refs.observer
        },
        agencyEdit: {
            get(){
                return this.agencies.find( agency => agency.id === this.selectedGroup.agency )
            },
           set( grupo ){
               const id  = grupo ? grupo.id : null
               return this.agencies.find( agency => agency.id === id )             
           } 
        },
        agentEdit: {
            get(){
                return this.agents.find( agency => agency.id === this.selectedGroup.agentname )
            },
           set( grupo ){
               const id  = grupo ? grupo.id : null
               return this.agents.find( agency => agency.id === id )             
           } 
        },
        groupTypeEdit: {
            get(){
                return this.groupTypes.find( groupType => groupType.id === this.selectedGroup.tipogrupo )
            },
           set( grupo ){
               const id  = grupo ? grupo.id : null
               return this.groupTypes.find( event => event.id === id )             
           } 
        },
        coordinatorEdit: {
            get(){
                return this.coordinators.find( coord => coord.id === this.selectedGroup.coordinador )
            },
           set( grupo ){
               const id  = grupo ? grupo.id : null
               return this.coordinators.find( coord => coord.id === id )             
           } 
        },
                         
    },
    methods: {
        moment,
        ...mapActions('init',['fetchAgents']),    
        ...mapMutations('init',['setAgencySelected','setAgents']),
        async updateGroup(){
            const fechaActual = moment().format("YYYY-MM-DD")
            const fechaInicio = document.getElementById('dateArrivalEdit').value
            const fechaFin = document.getElementById('dateDepartureEdit').value
            const isValidDates = moment(fechaFin).isSameOrAfter(fechaInicio, 'day')
            const isValidDateStart = moment(fechaInicio).isSameOrAfter(fechaActual, 'day')
            const isValidDateEnd = moment(fechaFin).isSameOrAfter(fechaActual, 'day')

            const success = await this.computedFormEditGroup.validate()
            if (!success) {
                return
            }

            if(fechaInicio !="" && fechaFin !=""){
                if(!isValidDates){
                    Vue.$toast.open({
                        message: `<strong>Fecha fin no debe ser menor a fecha de inicio</strong>`,
                        type: 'error',
                        position:'top-right'
                    })
                    return
                }
                if( !isValidDateStart || !isValidDateEnd ){
                    Vue.$toast.open({
                        message: `<strong>Las fechas de llegada y salida no deben ser menores a la fecha actual</strong>`,
                        type: 'error',
                        position:'top-right'
                    })
                    return
                }                
            }             
            // this.selectedGroup.groupname = this.selectedGroup.name
            this.selectedGroup.adult = parseInt(this.selectedGroup.adult)
            this.selectedGroup.child = parseInt(this.selectedGroup.child)
            this.selectedGroup.infant = parseInt(this.selectedGroup.infant) 
            delete this.selectedGroup.createdby
            this.selectedGroup.updatedby = this.idUser,
            this.$emit('update-group', this.selectedGroup )         
        },                    
        closeModalUpdateGroup(){
            this.selectedGroup.name = this.nombreGrupo 
            this.selectedGroup.grouphotel = this.hotelGrupo 
            this.selectedGroup.arrivaldate = this.fechaLlegadaGrupo 
            this.selectedGroup.departuredate = this.fechaSalidaGrupo 
            this.selectedGroup.adult = this.adultosGrupo 
            this.selectedGroup.child = this.niniosGrupo 
            this.selectedGroup.infant = this.infantesGrupo 
            this.selectedGroup.coordinador = this.coordinadorGrupo 
            this.$emit('close-modal-update-group')
        },
        blurSelectEditAgencia(){                     
            this.computedFormEditGroup.refs.agencia.validate()
        },
        blurSelectEditAgente(){                     
            this.computedFormEditGroup.refs.agente.validate()
        },
        blurSelectEditGroupType(){                     
            this.computedFormEditGroup.refs.grupo.validate()
        },
        blurSelectEditCoordinator(){                     
            this.computedFormEditGroup.refs.coordinador.validate()
        },
        async _setAgencySelected( agency ){            
            const idAgency = agency ? agency.id : null                  
            if( idAgency == null  ){ 
                this.selectedGroup.agency = null            
                this.selectedGroup.agentname = null                
                await this.setAgents([])
            }            
            if( idAgency != null ){
                this.selectedGroup.agency = idAgency            
                this.selectedGroup.agentname = null
                await this.setAgencySelected( idAgency )
                await this.fetchAgents()
            }
        }, 
        _setAgent(  agent  ){
            if( agent ){
                const { id } =  agent   
                this.selectedGroup.agentname = id            
            }
            if( !agent  ){
                this.selectedGroup.agentname = null            
            }
        },
        _setGroupType( grupo ){
            if(grupo){
                const { id } =  grupo  
                this.selectedGroup.tipogrupo = id            
            }
            if( !grupo ){
                this.selectedGroup.tipogrupo = null            
            }
        },
        _setCoordinator( coordinador  ){
            if(coordinador ){
                const { id } =  coordinador   
                this.selectedGroup.coordinador = id            
            }
            if( !coordinador ){
                this.selectedGroup.coordinador = null            
            }
        },
        formatPhoneNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which)
            //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
            const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
            const respuesta = codesAllowed.includes(keyCode)
			if (!respuesta) {                                       
				event.preventDefault()
			}
        },
        checkIfFormatEmailIsValid( email ){     
            const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if( email && email !='' ){
                if( reg.test(email) ){                      
                    return ''
                }
                if( !reg.test(email) ){                       
                    return 'Email invalido'
                }
            }
        },
    },
}

</script>
